import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CheckboxFormField } from "../common/components/FormFields";

export interface CdcConsentAndDeclarationFieldsProps {}

export function CdcConsentAndDeclarationFields({}: CdcConsentAndDeclarationFieldsProps) {
  const namespace = "consentAndDeclaration";

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Consent and Declaration</Typography>
      </Box>
      <Grid container spacing={0.5} flex={1}>
        <Grid item xs={12}>
          <CheckboxFormField
            name={`${namespace}.agreesToConsentTerms`}
            mustBeTrue
            label="I hereby consent to the Inuvialuit Regional Corporation’s collection, use and disclosure of the information referred to above for the purpose of an application for enrolment to a licensed facility under the Inuvialuit Early Childhood Program."
          />
        </Grid>
      </Grid>
    </Box>
  );
}
