import React from "react";
import { Box, Typography } from "@mui/material";
import { FileFormField } from "../common/components/FormFields";

export interface LettersOfSupportProps {}

export function LettersOfSupport({}: LettersOfSupportProps) {
  return (
    <Box>
      <Typography variant="h5">Letters of Support</Typography>
      <Typography paragraph>
        With each application requests’, children will require a letter of
        support from a health, social or educational professional. The letter of
        support must clearly describe or link the requested support and services
        to meet the children’s health, social or educational needs.
      </Typography>
      <Typography paragraph>
        For requests related to financial hardship these letters usually speak
        to the family's situation and why they require financial assistance.
        These letters typically come from counsellors, social workers,
        physicians, etc. The letter should clearly describe how the requested
        items are necessary for the child.
      </Typography>
      <FileFormField name="lettersOfSupport" multiFile noIcon />
    </Box>
  );
}
