import React from "react";
import { Stack, Typography } from "@mui/material";

export interface NotFoundPageProps {
  internal?: boolean;
}

export function NotFoundPage({ internal }: NotFoundPageProps) {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={5}
      p={5}
    >
      <img src="/static/images/irc_watermark.png" width={300} />
      <Stack gap={2}>
        <Typography
          variant="h1"
          fontSize={64}
          fontFamily='"Open Sans", sans-serif'
          fontWeight={700}
          color="primary.main"
        >
          Page Not Found
        </Typography>
        <Typography>
          Please contact IRC for support at{" "}
          {!internal && (
            <>
              <a href="mailto:info@inuvialuit.com">info@inuvialuit.com</a> or
              call <b>(867) 777-7000</b>
            </>
          )}
          {internal && (
            <>
              <a href="mailto:tickets@inuvialuit.com">tickets@inuvialuit.com</a>
            </>
          )}
        </Typography>
      </Stack>
    </Stack>
  );
}
