import { apiResponse } from "../common/api/Interfaces/response";
import { sendFormDataPost, sendGet } from "../common/networking/api";
import { IHAPFormSubmission } from "./IHAPForm";
import { serialize } from "object-to-formdata";

export async function submitIHAP(
  form: IHAPFormSubmission,
  recaptchaToken: string,
  recaptchaAction: string
): Promise<apiResponse> {
  const formData = serialize(
    { form, recaptchaToken, recaptchaAction },
    { indices: true }
  );

  return await sendFormDataPost("/api/ihap-application", formData);
}

export interface IHAPSettings {
  enabled: boolean;
}

export async function getIHAPSettings() {
  return await sendGet<IHAPSettings>("/api/ihap-application-enabled");
}
