import { sendFormDataPost } from "../networking/api";
import { apiResponse } from "./Interfaces/response";
import { serialize } from "object-to-formdata";
import { CdcFormResult } from "../../CdcForm/CdcTypes";

export async function submitCdcApplication(
  application: CdcFormResult,
  recaptchaToken: string,
  recaptchaAction: string
): Promise<apiResponse> {
  const formData = serialize(
    { application, recaptchaToken, recaptchaAction },
    { indices: true }
  );

  return await sendFormDataPost("/api/cdc-application", formData);
}
