import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  CheckboxFormField,
  RadioFormField,
  RadioFormFieldOption,
} from "../common/components/FormFields";
import { FormFieldWrapper } from "../common/components/FormFields/FormFieldWrapper";

export interface CdcSpecialPermissionFieldsProps {}

export function CdcSpecialPermissionFields({}: CdcSpecialPermissionFieldsProps) {
  const namespace = "specialPermissions";

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Special Permissions</Typography>
      </Box>
      <Grid container spacing={0.5} flex={1}>
        <Grid item xs={12}>
          <Box ml={5}>
            <Typography>
              <Box sx={{ fontWeight: "bold" }}>
                Permission For My Child To Be Taken On Excursions By The Program
                Staff
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CheckboxFormField
            name={`${namespace}.hasExursionPermission`}
            label="I give permission for my child to leave the Program Facility in the company of qualified staff for walks and excursions within the community/town."
          />
        </Grid>
        <Grid item xs={12}>
          <Box ml={5}>
            <Typography>
              <Box sx={{ fontWeight: "bold" }}>
                Permission For My Child To Be Transported By A Vehicle For The
                Purpose Of Excursions
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CheckboxFormField
            name={`${namespace}.hasTransportationPermission`}
            label="I give permission for my child to travel in a vehicle provided by the Program for the purpose of excursions."
          />
        </Grid>
        <Grid item xs={12}>
          <Box ml={5}>
            <Typography variant="caption">
              <b>NOTE:</b> For each excursion using vehicles the
              parents/guardians will be informed as to how their child will be
              secured/restrained (e.g. booster seat) or if the vehicle does not
              have seat belts (e.g. school bus).
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box ml={5}>
            <Typography>
              I hereby consent that the photos/videos taken of [name of child]
              during their time at the Program Facility may be used by IRC. I
              agree that these pictures or videos may be used in all Inuvialuit
              Early Childhood Program-related materials or media outlets,
              including (but not limited to) IRC’s website, brochures, emails,
              social media accounts, etc. I understand that personal
              identification (ie. name, date of birth, etc.) will not appear
              alongside any photos or videos. Furthermore, I consent that such
              photographs/videos shall be the property of IRC, which has the
              right to duplicate, reproduce and make other uses as it deems
              necessary within the boundaries of this release.
            </Typography>
          </Box>
        </Grid>
        <RadioFormField
          name={`${namespace}.photoPermission`}
          label=""
          required
          options={[
            "I give my permission to use my child’s photographs, etc. AS DESCRIBED ABOVE",
            "I give my permission for my child’s picture, etc. to be used in emails and internal publications only",
            "I DO NOT consent to having photographs, etc. of my child used IN ANY WAY, as specified above",
          ]}
        />
      </Grid>
    </Box>
  );
}
