import { Box, Card, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Outlet } from "react-router-dom";
import logo from "./logo.png";
import { DevLinks } from "./DevLinks";

export interface FormLayoutProps {}

export const FormLayout: React.FC<FormLayoutProps> = ({}) => {
  return (
    <Box
      minHeight="100vh"
      sx={{ bgcolor: "#f4f5f5" }}
      pb={5}
      position="relative"
    >
      <Stack
        bgcolor="primary.main"
        color="primary.contrastText"
        mb={2}
        p={1}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <img src={logo} alt="form logo" style={{ maxWidth: "40%" }} />
        <Box textAlign="right" alignSelf="flex-end">
          <Typography component="div" variant="caption">
            Bag Service #21 | Inuvik, NT X0E 0T0
          </Typography>
          <Typography component="div" variant="caption">
            <b>Tel:</b> (867) 777-7000 <b>Fax:</b> (877) 289-2389
          </Typography>
          <Typography component="div" variant="caption">
            <b>Email:</b> info@inuvialuit.com <b>Web:</b> www.inuvialuit.com
          </Typography>
        </Box>
      </Stack>
      <Container>
        <Card>
          <Box p={2}>
            <Outlet />
          </Box>
        </Card>
      </Container>
      <Box position="absolute" top={10} right={20}>
        <DevLinks />
      </Box>
    </Box>
  );
};
