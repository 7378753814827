import React from "react";
import { CfiChild } from "./cfiTypes";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FieldArray } from "react-final-form-arrays";
import { MuiAdd, MuiPerson } from "../common/icons";
import MuiDelete from "@mui/icons-material/Delete";
import { DateFormField, TextFormField } from "../common/components/FormFields";
import dayjs from "dayjs";

interface ChildFieldsProps {
  namespace: string;
}

function ChildFields({ namespace }: ChildFieldsProps) {
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Grid container spacing={0.5} flex={1}>
      <Grid item lg={3} sm={6} xs={12}>
        <TextFormField
          name={`${namespace}.name`}
          label="Name"
          required
          icon={<MuiPerson />}
        />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <DateFormField
          name={`${namespace}.dateOfBirth`}
          label="Date Of Birth"
          required
          stringOutput
          noIcon={sm}
          minDate={dayjs().subtract(20, "years")}
        />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <TextFormField name={`${namespace}.nihb`} label="N#" noIcon={lg} />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <TextFormField
          name={`${namespace}.healthCardNumber`}
          label="Health Card Number"
          noIcon={sm}
        />
      </Grid>
    </Grid>
  );
}

export const DEFAULT_CHILD_FIELDS: Partial<CfiChild> = {
  name: "",
  healthCardNumber: "",
};

export interface ChildrenFieldsProps {}

export function ChildrenFields({}: ChildrenFieldsProps) {
  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Children</Typography>
      </Box>
      <FieldArray<Partial<CfiChild>>
        name="children"
        render={({ fields }) => (
          <Stack gap={1}>
            {fields.map((name, index) => (
              <Stack direction="row" alignItems="center" gap={1}>
                <ChildFields namespace={name} />
                <IconButton color="error" onClick={() => fields.remove(index)}>
                  <MuiDelete />
                </IconButton>
              </Stack>
            ))}
            <Box ml={5}>
              <Button
                startIcon={<MuiAdd />}
                variant="outlined"
                onClick={() => fields.push({ ...DEFAULT_CHILD_FIELDS })}
              >
                Add Child
              </Button>
            </Box>
          </Stack>
        )}
      />
    </Box>
  );
}
