import { Box, BoxProps } from "@mui/material";
import React, { useRef } from "react";

export interface ScrollToBottomDetectionBoxProps extends BoxProps {
  onScrolledToBottom?: () => void;
}

export const ScrollToBottomDetectionBox: React.FC<
  ScrollToBottomDetectionBoxProps
> = ({ onScrolledToBottom, ...boxProps }) => {
  const eventFiredRef = useRef(false);

  const handleScroll = (e: React.UIEvent) => {
    //We detect if we've scrolled "close enough" (within 5 pixels
    // of the bottom) since trying to be pixel perfect can break
    // on monitors with scaling
    if (
      !eventFiredRef.current &&
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop <
        e.currentTarget.clientHeight + 5
    ) {
      onScrolledToBottom && onScrolledToBottom();
      eventFiredRef.current = true;
    }
  };

  return <Box {...boxProps} onScroll={handleScroll}></Box>;
};
