import { ThemeOptions } from "@mui/material";

export const inquiryTheme: ThemeOptions = {
  typography: {
    h4: {
      borderBottom: "2px solid black",
      marginBottom: 10,
    },
  },
};
