import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material";
import { deepmerge } from "@mui/utils";
import React from "react";

export interface NestedThemeProviderProps {
  innerTheme: ThemeOptions;
  children?: React.ReactNode;
}

export const NestedThemeProvider: React.FC<NestedThemeProviderProps> = ({
  innerTheme,
  children,
}) => {
  return (
    <ThemeProvider
      theme={(outerTheme) => createTheme(deepmerge(outerTheme, innerTheme))}
    >
      {children}
    </ThemeProvider>
  );
};
