import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  AddressFormFields,
  InternationalAddressFields,
  SelectFormField,
  TextFormField,
} from "../common/components/FormFields";
import MuiPeople from "@mui/icons-material/People";
import { CdcContactFields } from "./CdcContactFields";

export interface CdcParentGuardianFieldProps {}

export function CdcParentGuardianFields({}: CdcParentGuardianFieldProps) {
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const namespace = "parentGuardian";

  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Parent/Guardian Information</Typography>
      </Box>
      <Grid container spacing={0.5} flex={1}>
        <Grid item lg={3} sm={6} xs={12}>
          <TextFormField
            name={`${namespace}.motherName`}
            label="Mother's Name"
            required
            icon={<MuiPeople />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <TextFormField
            name={`${namespace}.fatherName`}
            label="Father's Name"
            required
            noIcon={sm}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <SelectFormField
            name={`${namespace}.status`}
            label="Status"
            options={["Employed", "Student", "Stay Home"]}
            required
            noIcon={sm}
          />
        </Grid>
        <Grid item xs={12}>
          <AddressFormFields
            name={`${namespace}.homeAddress`}
            required
            label="Home Address"
          />
        </Grid>
        <Grid item xs={12}>
          <AddressFormFields
            name={`${namespace}.workAddress`}
            label="Work Address"
          />
        </Grid>
        <Grid item xs={12}>
          <CdcContactFields namespace={namespace} />
        </Grid>
      </Grid>
    </Box>
  );
}
