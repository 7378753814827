import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { MuiAdd } from "../common/icons";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import {
  RadioFormField,
  SelectFormField,
  SelectFormFieldOption,
  TextFormField,
} from "../common/components/FormFields";
import { useFormState } from "react-final-form";
import { IHAPFormSubmission } from "./IHAPForm";
import { FieldArray } from "react-final-form-arrays";
import MuiDelete from "@mui/icons-material/Delete";

function toRange(count: number): number[] {
  return Array.from(new Array(count), (x, i) => i);
}

export interface Household {
  householdMembers: HouseholdMember[];
  previouslyRecievedAssistance: string;
  previousAssistanceInformation: string;
}

export enum HouseholdMemberRelationship {
  SPOUSE = "spouse",
  SON = "son",
  DAUGHTER = "daughter",
  OTHER = "other",
}

export interface HouseholdMember {
  firstName?: string;
  lastName?: string;
  relationship?: HouseholdMemberRelationship;
}

export const DEFAULT_MEMBER_VALUES = {
  firstName: "",
  lastName: "",
  relationship: undefined,
};

export const DEFAULT_HOUSEHOLD_VALUES = {
  householdMembers: [DEFAULT_MEMBER_VALUES],
  previouslyRecievedAssistance: "",
  previousAssistanceInformation: "",
};

const HouseholdMemberRelationshipFormOptions: SelectFormFieldOption[] = [
  {
    value: HouseholdMemberRelationship.OTHER,
    text: HouseholdMemberRelationship.OTHER,
  },
  {
    value: HouseholdMemberRelationship.SPOUSE,
    text: HouseholdMemberRelationship.SPOUSE,
  },
  {
    value: HouseholdMemberRelationship.DAUGHTER,
    text: HouseholdMemberRelationship.DAUGHTER,
  },
  {
    value: HouseholdMemberRelationship.SON,
    text: HouseholdMemberRelationship.SON,
  },
];

export interface HouseholdPropsList {
  namespace: string;
}

export const Household: React.FC<HouseholdPropsList> = ({ namespace }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const {
    values: { household },
  } = useFormState<IHAPFormSubmission>({ subscription: { values: true } });
  const previouslyRecievedAssistance = household?.previouslyRecievedAssistance;

  return (
    <Box>
      <Box mb={0.5}>
        <Typography variant="h6">
          Household: (list all member of your household including yourself)
        </Typography>
      </Box>
      <FieldArray<HouseholdMember>
        name={namespace + ".householdMembers"}
        render={({ fields }) => (
          <Stack gap={2}>
            {fields.map((name, index) => (
              <Stack direction={"row"} gap={2}>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} md={4}>
                    <TextFormField
                      name={`${name}.firstName`}
                      label="First Name"
                      size="small"
                      icon={<FamilyRestroomIcon />}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFormField
                      name={`${name}.lastName`}
                      label="Last Name"
                      size="small"
                      noIcon={md}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SelectFormField
                      name={`${name}.relationship`}
                      label="Relationship"
                      noIcon={md}
                      size="small"
                      options={HouseholdMemberRelationshipFormOptions}
                      required={true}
                    />
                  </Grid>
                </Grid>
                <Button
                  color="error"
                  onClick={() => fields.remove(index)}
                  variant={"outlined"}
                >
                  <MuiDelete />
                </Button>
              </Stack>
            ))}
            <Box ml={5}>
              <Button
                startIcon={<MuiAdd />}
                variant="outlined"
                onClick={() => fields.push({ ...DEFAULT_MEMBER_VALUES })}
              >
                Add Household Member
              </Button>
            </Box>
          </Stack>
        )}
      ></FieldArray>
      <RadioFormField
        name={`${namespace}.previouslyRecievedAssistance`}
        label="Have you or any other members of your household recieved assistance under this program during the last three years?"
        options={["Yes", "No"]}
        required={true}
      />
      {previouslyRecievedAssistance === "Yes" && (
        <TextFormField
          name={`${namespace}.previousAssistanceInformation`}
          label="Assistance year(s), ammount(s), and equipment type(s)"
          multiline
        />
      )}
    </Box>
  );
};
