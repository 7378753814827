import { Box, Divider, Grid, useTheme } from "@mui/material";
import React from "react";
import { TextFormField } from "../../common/components/FormFields";
import { MuiPerson, MuiPhone } from "../../common/icons";

export interface LegalGuardianFieldsProps {}

export const LegalGuardianFields: React.FC<
  LegalGuardianFieldsProps
> = ({}: LegalGuardianFieldsProps) => {
  const theme = useTheme();

  return (
    <Box>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="legalGuardian.name"
            label="Legal Guardian Name"
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="legalGuardian.phoneNumber"
            label="Phone Number"
            icon={<MuiPhone />}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextFormField
            name="legalGuardian.address"
            label="Home Address(Street, Town)"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
