import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  InternationalAddressFields,
  TextFormField,
} from "../common/components/FormFields";
import MuiPeople from "@mui/icons-material/People";

export interface ParentFieldsProps {}

export function ParentFields({}: ParentFieldsProps) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Parents</Typography>
      </Box>
      <Grid container spacing={0.5}>
        <Grid item md={6} xs={12}>
          <TextFormField
            name="parents.names"
            label="Names"
            icon={<MuiPeople />}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextFormField name="parents.nihb" label="N#" noIcon={md} />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextFormField
            name="parents.contactNumber"
            label="Contact Number"
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextFormField
            name="parents.email"
            label="Email"
            noIcon={md}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <InternationalAddressFields
            namespace="parents.address"
            label="Address"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
