import { AddressChangeSubmission } from "../../AddressChangeForm/AddressChangeSubmission";
import { sendPost } from "../networking/api";
import { apiResponse } from "./Interfaces/response";

  export async function submitAddressChange(addressChange: AddressChangeSubmission, recaptchaToken: string, recaptchaAction: string): Promise<apiResponse> {
    return await sendPost('/api/addressChange/add', {
      addressChange,
      recaptchaToken,
      recaptchaAction
    });
  }
 
