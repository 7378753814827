import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  DateFormField,
  RadioFormField,
  TextFormField,
} from "../../common/components/FormFields";
import { MuiPerson } from "../../common/icons";
import { useFormState } from "react-final-form";
import { ProjectJewelFormResult } from "../projectJewel";

const OTHER = "Other";

export interface PersonalInformationProps {}

export function PersonalInformation({}: PersonalInformationProps) {
  const { values } = useFormState<ProjectJewelFormResult>();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Typography variant="h5" paragraph>
        Personal Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="firstName"
            label="First Name"
            required
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="lastName"
            label="Last Name"
            required
            noIcon={sm}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <DateFormField name="dateOfBirth" label="Date of Birth" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="nwtHealthCard"
            label="NWT Health Card #"
            required
            noIcon={sm}
          />
        </Grid>
        <Grid item md={values.ethnicity === OTHER ? 6 : 12} xs={12}>
          <RadioFormField
            name="ethnicity"
            label="Ethnicity"
            options={["Inuvialuit", "Gwich'in", "Métis", OTHER]}
            required
          />
        </Grid>
        {values.ethnicity === OTHER && (
          <Grid item md={6} xs={12}>
            <TextFormField
              name="customEthnicity"
              label="Custom Ethnicity"
              required
              noIcon={md}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
