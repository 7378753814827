import { Box, Grid, useTheme } from "@mui/material";
import React from "react";
import { TextFormField } from "../common/components/FormFields";

export interface BeneficiaryInfoProps {}

export const BeneficiaryInfo: React.FC<BeneficiaryInfoProps> = ({}) => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={2} mt={-1}>
        <Grid item xs={12} sm={6}>
          <TextFormField
            name="beneficiaryFileNumber"
            label="Beneficiary File Number"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
