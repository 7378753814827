import { sendPost } from "../networking";
import { apiResponse } from "./Interfaces/response";
import { ProjectJewelFormResult } from "../../ProjectJewelForm/projectJewel";

export async function submitJewelHealthDisclosure(
  submission: ProjectJewelFormResult,
  recaptchaToken: string,
  recaptchaAction: string
): Promise<apiResponse> {
  return await sendPost("/api/project-jewel/add", {
    submission,
    recaptchaToken,
    recaptchaAction,
  });
}
