import React, { createContext, useContext } from "react";
import { isLoggedIn } from "../api/authApi";
import { useAsyncData } from "../networking";

export interface AuthenticatedData {
  isLoggedIn?: boolean;
  loading: boolean;
  error?: Error | null;
}

const AuthenticatedContext = createContext<AuthenticatedData | null>(null);

export interface AuthenticatedProviderProps {
  children: React.ReactNode;
}

export const AuthenticatedProvider: React.FC<AuthenticatedProviderProps> = ({
  children,
}) => {
  const { data, isLoading, error } = useAsyncData(() => isLoggedIn(), []);
  return (
    <AuthenticatedContext.Provider
      value={{ isLoggedIn: data?.loggedIn, loading: isLoading, error }}
    >
      {children}
    </AuthenticatedContext.Provider>
  );
};

export function useAuthenticated() {
  const result = useContext(AuthenticatedContext);

  if (!result) {
    throw new Error(
      "useAuthenticated() cannot be used without being wrapped by a AuthenticatedProvider"
    );
  }

  return result;
}
