import React from 'react';

const Dashboard: React.FC = () => {
    return (
        <>
            <div>
                <a href="/internal/cfi-winter-clothing-application-export" download>
                    Export CFI Winter Clothing Applications
                </a>
            </div>
        </>
    );
};

export default Dashboard;