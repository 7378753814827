import React from "react";
import { Box, Typography } from "@mui/material";

export interface ProjectJewelHeaderInfoProps {}

export function ProjectJewelHeaderInfo({}: ProjectJewelHeaderInfoProps) {
  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h3" textAlign="center">
          Project Jewel: Health Disclosure Form
        </Typography>
      </Box>

      <Typography variant="h4" paragraph>
        INFORMATION ABOUT THIS FORM
      </Typography>
      <Typography paragraph>
        <b>CONSENT:</b> The federal legislation protecting your privacy requires
        the IRC to obtain your consent to collect, use and disclose your
        personal information for identified purposes. By signing this form, you
        are giving your consent.
      </Typography>
      <Typography paragraph>
        <b>PURPOSE OF COLLECTING YOUR INFORMATION:</b> Participants in Project
        Jewel may engage in strenuous outdoor activity, which some participants
        may find physically, emotionally and spiritually demanding. The personal
        and health information disclosed by the participant will only be used to
        help the Program facilitators prepare for and deliver the program as
        safely and effectively as possible.
      </Typography>
      <Typography paragraph>
        <b>DISCLOSURE OF YOUR INFORMATION:</b> The information may be disclosed
        to program facilitators and staff of the Inuvialuit Corporate Group on a
        strictly as required for the operation of Project Jewel.
      </Typography>
      <Typography paragraph>
        <b>ASSUMPTION OF RISK:</b> By collecting this information, IRC does not
        assume any risk on behalf of the participant. Please refer to the
        Participation Agreement for more information
      </Typography>
    </Box>
  );
}
