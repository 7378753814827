import { Stack, Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

//The other _____FormField components rely on this, but if you add any
// props, it should automatically propagate to every other component
// EXCEPT FOR TextFormField, which will need to be updated manually

export interface FormFieldWrapperProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  noIcon?: boolean;
}

export const FormFieldWrapper: React.FC<FormFieldWrapperProps> = ({
  icon,
  children,
  noIcon,
}) => {
  return (
    <Stack direction="row" alignItems="center">
      {!noIcon && (
        <Box width={40} color={grey[600]}>
          {icon}
        </Box>
      )}
      <Box flex={1}>{children}</Box>
    </Stack>
  );
};
