import React from "react";
import { useFormState } from "react-final-form";
import { IHAPFormSubmission } from "./IHAPForm";
import { RequestedEquipment } from "./RequestedHouseholdEquipment";
import { Alert } from "@mui/material";

export const RequestedEquipmentSum: React.FC<any> = ({}) => {
  const {
    values: { majorRequestedEquipment, minorRequestedEquipment },
  } = useFormState<IHAPFormSubmission>({ subscription: { values: true } });

  var minorRequestsSum = 0;
  minorRequestedEquipment?.map((equipment: RequestedEquipment) => {
    minorRequestsSum += parseFloat(equipment.requestedContribution ?? "");
  });
  var majorRequestsSum = 0;

  majorRequestedEquipment?.map((equipment: RequestedEquipment) => {
    majorRequestsSum += parseFloat(equipment.requestedContribution ?? "");
  });

  const total = majorRequestsSum + minorRequestsSum;
  return (
    <Alert variant="filled" severity="info">
      {"Total Requested Assistance of $" + total}.
    </Alert>
  );
};
