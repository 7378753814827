import { Typography } from "@mui/material";
import React from "react";

export interface ConsentTermsProps {}

export const ConsentTerms: React.FC<ConsentTermsProps> = ({}) => {
  return (
    <>
      <Typography variant="h5" paragraph>
        Introduction
      </Typography>
      <Typography paragraph>
        'Nanilavut' is Inuktitut for 'Let's find them'. The Nanilavut Initiative
        enables individuals and families to request information for family
        members who were relocated from their communities during the past
        tuberculosis epidemic between the 1940s to 1960s. The initiative will
        provide a range of supports to individuals and families including:
      </Typography>
      <ul>
        <Typography component="li">
          project managers with Inuvialuit Regional Corporation will be able to
          search a government database on behalf of family members and provide
          any information on their loved one;
        </Typography>
        <Typography component="li">
          commemorative events and monuments
        </Typography>
        <Typography component="li">
          compassionate travel support for family members;
        </Typography>
        <Typography component="li">enhancements to gravesites; and</Typography>
        <Typography component="li">health awareness.</Typography>
      </ul>
      <Typography paragraph>
        The development government database and other supports under the
        Nanilavut Initiative were guided by the Nanilavut Working Group,
        comprised of the Government of Canada, Inuit Tapiriit Kanatami, Nunavut
        Tunngavik Incorporated, Inuvialuit Regional Corporation, Makivik
        Corporation, Nunatsiavut Government, Qikiqtani Inuit Association,
        Kitikmeot Inuit Association, Kivalliq Inuit Association, Government of
        Nunavut, Government of the Northwest Territories and Pauktuutit Inuit
        Women of Canada.
      </Typography>
      <Typography paragraph>
        In order for individuals to participate in the Nanilavut Initiative,
        Inuvialuit Regional Corporation (“IRC”), requires your consent to
        collect, use and disclose certain personal information about you. IRC
        will collect, use and disclose your personal information in accordance
        with the{" "}
        <i>Personal Information Protection and Electronic Documents Act</i>,
        S.C. 2000, c.5.
      </Typography>
      <Typography variant="h5" paragraph>
        Collection, Use and Disclosure of Your Personal Information
      </Typography>
      <Typography paragraph>
        IRC will collect, use and disclose your information to assist you with
        your request for information or supports under the Nanilavut Initiative.
      </Typography>
      <Typography paragraph>
        IRC will collect your personal information and information about your
        family to open a file within IRC's internal databases under your name in
        order to keep all information related to your inquiry organized in a
        central location.
      </Typography>
      <Typography paragraph>
        IRC will use your personal information and information about your family
        in a number of respects consistent with your participation in the
        Nanilavut Initiative, namely:
      </Typography>
      <ul>
        <Typography component="li">
          to submit requests to the federal government for documents or
          information related to you or your family members, including but not
          limited to the Inuit Nanilavut database;
        </Typography>
        <Typography component="li">
          to submit requests to provincial or territorial governments for
          documents or information related to you or your family;
        </Typography>
        <Typography component="li">
          to submit requests to other third-party entities for documents or
          information to you or your family, including but not limited to other
          family members, health authorities, hospitals, municipalities,
          archives, libraries, cemeteries and funeral homes.
        </Typography>
        <Typography component="li">
          to evaluate whether services can be provided to you, including but not
          limited to services related to commemorative events and monuments,
          travel, gravesite enhancement, health and counselling;
        </Typography>
        <Typography component="li">
          to further IRC’s mandates under the Inuvialuit Final Agreement,
          including lobbying and funding requests for federal, provincial and
          territorial governments, for statistical, scholarly study or research
          purposes, and for internal IRC program development; and
        </Typography>
        <Typography component="li">
          for reporting purposes as may be required by any funding contribution
          agreements.
        </Typography>
      </ul>
      <Typography paragraph>
        IRC may also disclose your personal information to other third parties
        in certain circumstances not related to the Nanilavut Initiative:
      </Typography>
      <ul>
        <Typography component="li">
          to respond to law enforcement requests, including but not limited to
          complying with warrants, subpoenas, or production orders or other
          legally binding orders from a court or investigatory authority. IRC
          will not provide information except where required by applicable law;
          and
        </Typography>
        <Typography component="li">
          to enforce IRC’s rights, including but not limited to the event of a
          breach of an agreement or contravention of law. For example, IRC will
          use any consents or authorizations you provide to confirm its
          authority to collect, use and disclose your personal in the event of
          any allegation of a privacy breach or in response to any related
          offences alleged against IRC or its agents.
        </Typography>
      </ul>
      <Typography variant="h5" paragraph>
        Safeguards, Retention and Destruction
      </Typography>
      <Typography paragraph>
        IRC has implemented policies and procedures, as well as physical and
        technical security precautions to prevent unauthorized access, use,
        modification or disclosure of personal information that it collects and
        uses.
      </Typography>
      <Typography paragraph>
        We retain your personal information relating to your participation in
        the Nanilavut Initiative indefinitely. You may request access to
        personal information or make a request that IRC destroy any personal
        information it holds by making a written request to IRC at the contact
        details below. Please note that in the event of destruction of personal
        information IRC may retain some information in a de-identified form for
        research purposes.
      </Typography>
      <Typography variant="h5">Complaints</Typography>
      <Typography paragraph>
        Please note that you have a right to file a complaint to the Office of
        the Privacy Commissioner of Canada regarding IRC’s handling of your
        personal information. To do so, please contact the Privacy Commissioner
        at 1-800-282-1376.
      </Typography>
      <Typography variant="h5">
        How to Contact IRC About Your Personal Information
      </Typography>
      <Typography paragraph>
        Do you have questions not answered here? Would you like help obtaining
        assistance to access your personal information or requesting it be
        destroyed? Do you want to make a complaint? Please contact our Privacy
        Officer, George Parkes, who is accountable for our personal information
        handling practices. They can be reached at:
      </Typography>
      <Typography>gparkes@inuvialuit.com</Typography>
      <Typography>Inuvialuit Regional Corporation</Typography>
      <Typography>107 Mackenzie Road</Typography>
      <Typography>Bag Service #21</Typography>
      <Typography>Inuvik, NT X0E 0T0</Typography>
    </>
  );
};
