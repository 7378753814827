import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getTravelRequest,
  submitTravelRequest,
  updateTravelRequest,
} from "../common/api/travelRequestApi";
import { TravelRequestSubmission } from "./TravelRequestSubmission";
import { ErrorNotification } from "../common/components/ErrorNotification";
import { useAsyncData } from "../common/networking";
import { TravelRequestSkeleton } from "./TravelRequestSkeleton";
import { TravelRequestReviewError } from "./TravelRequestReviewError";
import { ConfirmTravelRequest } from "./ConfirmTravelRequest";
import { TravelFormWrapper } from "./TravelFormWrapper";
import { TravelRequestApproval } from "./TravelRequestApproval";

const RECAPTCHA_ACTION = "SubmitTravelForm";

export interface TravelRequestProps {}

export const TravelRequest: React.FC<TravelRequestProps> = ({}) => {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const { id: stringId } = useParams<{ id?: string }>();
  const id = stringId ? Number(stringId) : undefined;

  const {
    data: editableTravelRequest,
    isLoading: loadingTravelRequests,
    error,
  } = useAsyncData(async () => (id ? getTravelRequest(id) : undefined), [id]);

  const theme = useTheme();
  const [showSaved, setShowSaved] = useState(false);
  const [showError, setShowError] = useState<boolean>();

  const [currentTravelRequest, setCurrentTravelRequest] =
    useState<TravelRequestSubmission>();
  const [showConfirm, setShowConfirm] = useState(false);

  const confirm = (travelForm: TravelRequestSubmission) => {
    setCurrentTravelRequest(travelForm);
    setShowConfirm(true);
  };

  const submit = async () => {
    if (!currentTravelRequest) {
      throw new Error("Tried to submit a travel request without confirming");
    }

    try {
      setSubmitting(true);
      if (id) {
        await updateTravelRequest(id, currentTravelRequest);
        setSubmitting(false);
        navigate("/success");
      } else {
        const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
        const token = await grecaptcha.enterprise.execute(clientKey, {
          action: RECAPTCHA_ACTION,
        });

        await submitTravelRequest(
          currentTravelRequest,
          token,
          RECAPTCHA_ACTION
        );
        setSubmitting(false);
        navigate("/success");
      }
    } catch {
      setSubmitting(false);
      setShowError(true);
    }
  };

  if (error) {
    return <TravelRequestReviewError error={error} />;
  }

  if (id != null && loadingTravelRequests) {
    return <TravelRequestSkeleton />;
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5" mb={2}>
          Travel Request {id ? "Review" : "Form"}
        </Typography>
      </Box>
      <TravelFormWrapper
        onSubmit={confirm}
        id={id}
        editableTravelRequest={editableTravelRequest?.travelRequest}
        canEdit={editableTravelRequest?.canEdit}
        canSeeEstimate={editableTravelRequest?.canSeeEstimate}
        canEstimate={editableTravelRequest?.canEstimate}
        isApproved={editableTravelRequest?.isApproved}
      />
      <TravelRequestApproval
        id={id}
        canApprove={editableTravelRequest?.canApprove}
        onError={() => setShowError(true)}
      />
      <ErrorNotification open={showError} onClose={() => setShowError(false)} />
      <Snackbar
        open={showSaved}
        autoHideDuration={10000}
        onClose={() => setShowSaved(false)}
      >
        <Alert
          onClose={() => setShowSaved(false)}
          sx={{ width: "100%" }}
          elevation={6}
          variant="filled"
        >
          Form Saved
        </Alert>
      </Snackbar>
      <Dialog open={showConfirm} onClose={() => setShowConfirm(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <ConfirmTravelRequest
            tripSegments={currentTravelRequest?.tripSegments ?? []}
            submitting={submitting}
            onConfirm={submit}
            onCancel={() => setShowConfirm(false)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
