import { Typography, Grid, Box } from "@mui/material";
import React from "react";
import {
  DateFormField,
  RadioFormField,
  TextFormField,
} from "../../common/components/FormFields";
import { MuiPerson } from "../../common/icons";

export interface ChildInfoProps {}

export const ChildInfo: React.FC<ChildInfoProps> = ({}) => {
  return (
    <Box>
      <Typography variant="h5">Child's Information</Typography>
      <Grid container spacing={2} alignItems="center" mt={1}>
        <Grid item xs={12} sm={6}>
          <TextFormField name="childFirstName" label="First Name" required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFormField
            name="childLastName"
            label="Last Name"
            required
            noIcon
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFormField name="childInuvialukName" label="Inuvialuk Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RadioFormField
            name="gender"
            label="Gender"
            options={["Female", "Male", "Other"]}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateFormField name="dateOfBirth" label="Date Of Birth" required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFormField
            name="healthCardNumber"
            label="Health Card Number"
            noIcon
          />
        </Grid>
      </Grid>
    </Box>
  );
};
