import React from "react";
import { Box, Typography } from "@mui/material";

export interface CdcHeaderProps {}

export function CdcHeader({}: CdcHeaderProps) {
  return (
    <Box>
      <Typography variant="h4" paragraph>
        Inuvialuit Early Childhood Programs
      </Typography>
      <Typography variant="h6" paragraph>
        Application For Enrollment In A Licenced Facility
      </Typography>
      <Typography paragraph>
        This personal information is being collected under the authority of the
        Child Day Care Act and the Child Day Care Standards Regulations and is
        used to monitor the safety of children in the facility.It is protected
        by the privacy provisions of the Access to Information and Protection of
        Privacy Act. If you have any questions about the collection, contact the
        Manager at 1-887-777-7020.
      </Typography>
    </Box>
  );
}
