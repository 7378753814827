import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  RadioFormField,
  TextFormField,
} from "../../common/components/FormFields";

export interface HealthInformationProps {}

export function HealthInformation({}: HealthInformationProps) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RadioFormField
          name="lastDrugUsage"
          label="Date of last alcohol/drug use"
          required
          options={[
            "Past 7 days",
            "Past 14 days",
            "1 month",
            "Between 1 - 3 months",
            "Between 6 months - 1 year",
            "More than one year",
            "Never",
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <TextFormField
          name="allergies"
          label="Allergies (please list)"
          multiline
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <RadioFormField
          name="epipen"
          label="Epipen"
          options={["Yes", "No"]}
          required
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <RadioFormField
          name="pregnant"
          label="Pregnant"
          options={["Yes", "No"]}
          required
          noIcon={sm}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextFormField
          name="healthIssues"
          label="Health Issues"
          helperText="Please disclose any health issues or physical limitations about which the facilitator should know so they can make the necessary accommodations"
          multiline
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextFormField
          name="prescription"
          label="Current prescription medications and instructions"
          multiline
          noIcon={sm}
        />
      </Grid>
    </Grid>
  );
}
