import { Box, Divider, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { SubmitButton } from "../common/components/SubmitButton";
import { TravelRequestSubmission } from "./TravelRequestSubmission";
import {
  approveRequest
} from "../common/api/travelRequestApi";
import { useNavigate } from "react-router-dom";

export interface TravelRequestApprovalProps {
  id?: number;
  onError: () => void;
  canApprove?: boolean;
}

export const TravelRequestApproval = ({
  id,
  onError,
  canApprove,
}: TravelRequestApprovalProps) => {
  const navigate = useNavigate();

  const [isApproving, setApproving] = useState(false);

  const approve = async () => {
    if (id == null) {
      throw new Error("Tried to approve a non-existent submission");
    }

    try {
      setApproving(true);
      await approveRequest(id);
      navigate("/approved");
    } catch {
      onError();
    }

    setApproving(false);
  };

  if (!id || !canApprove) {
    return null;
  }

  return (
    <Box>
      <Box py={2}>
        <Divider />
      </Box>
      <Typography variant="h5" paragraph>
        Supervisor Approval
      </Typography>
      <SubmitButton
        color="success"
        text="Approve"
        onClick={approve}
        isSubmitting={isApproving}
      />
    </Box>
  );
};
