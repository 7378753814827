import React from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export interface SubmitButtonProps
  extends Omit<ButtonProps, "startIcon" | "size" | "children" | "variant"> {
  icon?: React.ReactNode;
  isSubmitting?: boolean;
  text?: string;
}

export function SubmitButton({
  icon,
  isSubmitting,
  text,
  ...buttonProps
}: SubmitButtonProps) {
  return (
    <Button
      {...buttonProps}
      size="large"
      variant="contained"
      startIcon={
        isSubmitting ? (
          <CircularProgress color="inherit" size={21} />
        ) : (
          icon ?? <CheckIcon />
        )
      }
    >
      {text ?? "Submit"}
    </Button>
  );
}
