import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  AddressFormFields,
  RadioFormField,
  TextFormField,
} from "../../common/components/FormFields";

export interface RegistrantInfoProps {}

export const RegistrantInfo: React.FC<RegistrantInfoProps> = ({}) => {
  return (
    <Box>
      <Typography variant="h6">Registrant's Information</Typography>
      <Grid container spacing={2} mt={-1}>
        <Grid item xs={12} sm={6}>
          <TextFormField
            name="registrantFirstName"
            label="First Name"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFormField name="registrantLastName" label="Last Name" required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RadioFormField
            name="registrantRelation"
            label="Relation to Child"
            options={["Parent", "Adopted Parent", "Grandparent", "Guardian"]}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFormField name="registrantEmail" label="Email" required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFormField name="registrantPhoneNumber" label="Phone" required />
        </Grid>
        <Grid item xs={12}>
          <AddressFormFields
            name="address"
            label="Current Address"
            required={true}
            />
        </Grid>
      </Grid>
    </Box>
  );
};
