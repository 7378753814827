import React from "react";
import { Grid, Typography } from "@mui/material";
import { TextFormField } from "../common/components/FormFields";
import { MuiComment, MuiPerson } from "../common/icons";
import { FormFieldWrapper } from "../common/components/FormFields/FormFieldWrapper";
import { TripSegmentsSection } from "./TripSegmentsSection";

export interface TravelRequestFieldsProps {
  disabled?: boolean;
  canSeeEstimate?: boolean;
  canEstimate?: boolean;
}

export function TravelRequestFields({
  disabled,
  canSeeEstimate,
  canEstimate,
}: TravelRequestFieldsProps) {
  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={12} md={true}>
          <TextFormField
            name="travelersFirstName"
            label="Traveler's First Name"
            required
            disabled={disabled}
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={true}>
          <TextFormField
            name="travelersLastName"
            label="Traveler's Last Name"
            required
            disabled={disabled}
            noIcon
          />
        </Grid>
        <Grid item xs={12} sm={12} md={true}>
          <TextFormField
            name="coding"
            label="Coding"
            required
            disabled={disabled}
            noIcon
          />
        </Grid>
        <Grid item xs={12} sm={12} md={true}>
          <TextFormField
            name="department"
            label="Department"
            required
            disabled={disabled}
            noIcon
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={12} md={true}>
          <TextFormField
            name="supervisorsFirstName"
            label="Supervisor's First Name"
            required
            disabled={disabled}
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={true}>
          <TextFormField
            name="supervisorsLastName"
            label="Supervisor's Last Name"
            required
            disabled={disabled}
            noIcon
          />
        </Grid>
        <Grid item xs={12} sm={12} md={true}>
          <TextFormField
            name="supervisorsEmail"
            label="Supervisor's Email"
            required
            disabled={disabled}
            noIcon
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={12} md={true}>
          <TextFormField
            name="comments"
            label="Reasons for stay/extra comments"
            disabled={disabled}
            multiline={true}
            icon={<MuiComment />}
          />
        </Grid>
      </Grid>
      <FormFieldWrapper>
        <TripSegmentsSection
          disabled={disabled}
          canSeeEstimate={canSeeEstimate}
          canEstimate={canEstimate}
        />
      </FormFieldWrapper>
      <Grid spacing={2} mb={2}>
        <Typography sx={{ fontWeight: "bold" }}>
          Please note all Air Canada bookings are booked in flex seating and are
          non-refundable. Please ensure you have your correct travel date and
          preference before submitting
        </Typography>
      </Grid>
    </>
  );
}
