import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { MuiAdd } from "../common/icons";
import {
  RadioFormField,
  SelectFormField,
  SelectFormFieldOption,
  TextFormField,
} from "../common/components/FormFields";
import SnowmobileIcon from "@mui/icons-material/Snowmobile";
import { FieldArray } from "react-final-form-arrays";
import MuiDelete from "@mui/icons-material/Delete";

function toRange(count: number): number[] {
  return Array.from(new Array(count), (x, i) => i);
}

export enum EquipmentType {
  SNOWMOBILE = "Snowmobile",
  BOAT = "Boat",
  ATV = "ATV",
  OUTBOARD_MOTOR = "Outboard Motor",
  OTHER = "Other",
}

export interface HouseholdEquipment {
  makeModel?: string;
  year?: string;
  condition?: string;
  comments?: string;
  type?: EquipmentType;
}

export const DEFAULT_HOUSEHOLD_EQUIPMENT_VALUES = {
  makeModel: "",
  year: "",
  condition: "",
  comments: "",
  type: undefined,
};

const HouseholdEquipmentFormOptions: SelectFormFieldOption[] = [
  { value: EquipmentType.OTHER, text: EquipmentType.OTHER },
  { value: EquipmentType.SNOWMOBILE, text: EquipmentType.SNOWMOBILE },
  { value: EquipmentType.BOAT, text: EquipmentType.BOAT },
  { value: EquipmentType.ATV, text: EquipmentType.ATV },
  { value: EquipmentType.OUTBOARD_MOTOR, text: EquipmentType.OUTBOARD_MOTOR },
];

export interface HouseholdEquipmentPropsList {}

export const HouseholdEquipment: React.FC<HouseholdEquipmentPropsList> = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Box mb={0.5}>
        <Typography variant="h6">
          Please list current equipment in your household.
        </Typography>
      </Box>
      <FieldArray<HouseholdEquipment>
        name={"householdEquipment"}
        render={({ fields }) => (
          <Stack>
            {fields.map((name, index) => (
              <Stack direction={"row"} gap={2}>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} md={3}>
                    <SelectFormField
                      name={`${name}.type`}
                      label="Equipment Type"
                      icon={<SnowmobileIcon />}
                      size="small"
                      options={HouseholdEquipmentFormOptions}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextFormField
                      name={`${name}.makeModel`}
                      label="Make & Model"
                      size="small"
                      noIcon={md}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <TextFormField
                      name={`${name}.year`}
                      label="Year"
                      size="small"
                      noIcon={md}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextFormField
                      name={`${name}.condition`}
                      label="Condition"
                      size="small"
                      noIcon={md}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFormField
                      name={`${name}.comments`}
                      label="Comments"
                      size="small"
                      noIcon={md}
                      required={true}
                    />
                  </Grid>
                </Grid>
                <Button
                  color="error"
                  onClick={() => fields.remove(index)}
                  variant={"outlined"}
                >
                  <MuiDelete />
                </Button>
              </Stack>
            ))}
            <Box ml={5}>
              <Button
                startIcon={<MuiAdd />}
                variant="outlined"
                onClick={() =>
                  fields.push({ ...DEFAULT_HOUSEHOLD_EQUIPMENT_VALUES })
                }
              >
                Add Equipment
              </Button>
            </Box>
          </Stack>
        )}
      />
    </Box>
  );
};
