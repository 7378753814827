import React from "react";
import { Box, Typography } from "@mui/material";

export interface CfiHeaderProps {}

export function CfiHeader({}: CfiHeaderProps) {
  return (
    <Box>
      <Typography variant="h4" paragraph>
        Inuit Child First Initiative
      </Typography>
      <Typography variant="h6" paragraph>
        Application
      </Typography>
      <Typography paragraph>
        The Inuit Child First Initiative ensures all Inuit children have access
        to the essential government funded health, social and educational
        products, services and supports they need, when they need them. Child
        First Initiative is design to meet the unmet needs of children under the
        age of 19 in the NWT.
      </Typography>
    </Box>
  );
}
