import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useFormState } from "react-final-form";
import { communityApi } from "../../common/api";
import {
  TextFormField,
  AddressFormFields,
  RadioFormField,
  SelectFormField,
} from "../../common/components/FormFields";
import { MuiMap, MuiPerson, MuiPhone } from "../../common/icons";
import { useAsyncData } from "../../common/networking";
import { DeepPartial } from "../../common/types";
import { InquirySubmission } from "../InquiryForm";

export interface InquirerFieldsProps {}

export const InquirerFields: React.FC<InquirerFieldsProps> = ({}) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const { data: communities } = useAsyncData(
    () => communityApi.getCommunities(),
    []
  );

  const { values } = useFormState<DeepPartial<InquirySubmission>>({
    subscription: { values: true },
  });

  return (
    <Box>
      <Typography variant="h4">
        Information on Enquirer Looking for a Lost Loved One
      </Typography>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="inquirerFirstName"
            label="First Name"
            required
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="inquirerLastName"
            label="Last Name"
            noIcon={sm}
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="landClaimArea"
            label="Land Claim Area"
            required
            icon={<MuiMap />}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="beneficiaryNumber"
            label="Beneficiary Number"
            noIcon={sm}
            required
          />
        </Grid>
        <Grid item sm={values?.community === "Other" ? 6 : 12} xs={12}>
          <SelectFormField
            name="community"
            label="Community"
            options={
              communities ? [...communities?.map((a) => a.name), "Other"] : []
            }
            required
          />
        </Grid>
        {values?.community === "Other" && (
          <Grid item sm={6} xs={12}>
            <TextFormField
              name="customCommunity"
              label="Custom Community"
              required
              noIcon={sm}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <AddressFormFields
            name="mailingAddress"
            label="Mailing Address"
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="phoneNumber"
            label="Phone Number"
            required
            icon={<MuiPhone />}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="emailAddress"
            label="Email Address"
            noIcon={sm}
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="relationshipToIndividual"
            label="Relationship to Lost Loved One"
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <RadioFormField
            name="preferredMethodOfFollowUp"
            label="Preferred Method of Follow-up"
            options={["Email", "Phone"]}
            noIcon={sm}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextFormField
            name="inquirerAdditionalInformation"
            label="Other Relevant Information"
            multiline
          />
        </Grid>
      </Grid>
    </Box>
  );
};
