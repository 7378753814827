import { Box, Divider, Grid, useTheme } from "@mui/material";
import React from "react";
import { TextFormField } from "../../common/components/FormFields";
import { MuiPerson, MuiPhone } from "../../common/icons";

export interface CareGiverFieldsProps {}

export const CareGiverFields: React.FC<
  CareGiverFieldsProps
> = ({}: CareGiverFieldsProps) => {
  const theme = useTheme();

  return (
    <Box>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="careGiver.name"
            label="Care Giver Name"
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="careGiver.phoneNumber"
            label="Phone Number"
            icon={<MuiPhone />}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextFormField
            name="careGiver.address"
            label="Home Address(Street, Town)"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
