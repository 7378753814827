import { Box, Divider, Grid, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
  TextFormField,
  RadioFormField,
  DateFormField,
} from "../../common/components/FormFields";
import { MuiCake, MuiPerson, MuiPhone } from "../../common/icons";

export interface ChildFieldsProps {
  index: number;
}

function buildName(name: string, index: number) {
  return `children[${index}].${name}`;
}

export const ChildFields: React.FC<ChildFieldsProps> = ({
  index,
}: ChildFieldsProps) => {
  const theme = useTheme();

  const [otherOrgInfoNeeded, setOOtherOrgInfoNeeded] = useState(false);
  const required = index == 0;
  return (
    <Box>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={1}>
        <Grid item sm={4} xs={12}>
          <TextFormField
            name={buildName("name", index)}
            label="Child/Youth Name"
            required={required}
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DateFormField
            name={buildName("dateOfBirth", index)}
            label="Date of Birth"
            required={required}
            icon={<MuiCake />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RadioFormField
            name={buildName("gender", index)}
            label="Gender"
            options={["Female", "Male", "Other"]}
            required={required}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <RadioFormField
            name={buildName("affiliated", index)}
            label="Is the child affiliated with another Indigenous organization? (If yes, please provide their name and contact information)"
            options={["Yes", "No"]}
            required={required}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextFormField
            name={buildName("otherIndigenousOrgInfo", index)}
            label="Other Indigenous organization Info"
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextFormField
            name={buildName("NIHBNumber", index)}
            label="NIHB Client Identification Number (N-number)"
            required={required}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextFormField
            name={buildName("livingWith", index)}
            label="Currently Living with"
            required={required}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextFormField
            name={buildName("phoneNumber", index)}
            label="Phone Number"
            required={required}
            icon={<MuiPhone />}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextFormField
            name={buildName("homeAddress", index)}
            label="Home Address(Street, Town)"
            required={required}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
