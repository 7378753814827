import { Address } from "./types";

export const validate = {
  addressValid: (value?: Address): string | undefined =>
    value?.line1 == null &&
    value?.line2 == null &&
    value?.community == null &&
    value?.jurasdiction == null &&
    value?.country == null &&
    value?.postalCode == null
      ? "This address is required"
      : undefined,
};
