export function deepIndex(target: any, propertyChain: string) {
  return deepIndexArray(target, propertyChain.split("."));
}

function deepIndexArray(target: any, properties: string[]): any {
  if (target[properties[0]] != null) {
    if (properties.length > 1) {
      return deepIndexArray(target[properties[0]], properties.slice(1));
    }
  }

  return target[properties[0]];
}
