import React, { useMemo } from "react";
import { TravelRequestSubmission, TripTypes } from "./TravelRequestSubmission";
import dayjs from "dayjs";
import arrayMutators from "final-form-arrays";
import { TravelRequestFields } from "./TravelRequestFields";
import { SubmitButton } from "../common/components/SubmitButton";
import SaveIcon from "@mui/icons-material/Save";
import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircle";
import { Form } from "react-final-form";

export interface TravelFormWrapperProps {
  editableTravelRequest?: TravelRequestSubmission;
  id?: number;
  canEdit?: boolean;
  canEstimate?: boolean;
  canSeeEstimate?: boolean;
  isApproved?: boolean;
  onSubmit: (result: TravelRequestSubmission) => void;
}

export function TravelFormWrapper({
  editableTravelRequest,
  id,
  onSubmit,
  canSeeEstimate,
  canEstimate,
  canEdit,
  isApproved,
}: TravelFormWrapperProps) {
  const initialValues = useMemo(
    () => ({
      timezone: dayjs.tz.guess(),
      tripType: TripTypes.oneWay,
      tripSegments: [{}],
      ...editableTravelRequest,
    }),
    []
  );

  return (
    <Form<TravelRequestSubmission>
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, values, invalid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <TravelRequestFields
            disabled={editableTravelRequest != null && !canEdit}
            canEstimate={canEstimate}
            canSeeEstimate={canSeeEstimate}
          />

          {(id === undefined || canEdit) && (
            <SubmitButton
              type="submit"
              disabled={invalid}
              isSubmitting={submitting}
              icon={<SaveIcon />}
              text={canEstimate ? "Save And Submit Estimates" : "Save"}
            />
          )}

          {isApproved && (
            <Typography color="success.main">
              <CheckIcon sx={{ verticalAlign: "top" }} /> This travel request
              submission has been approved
            </Typography>
          )}
        </form>
      )}
    />
  );
}
