import { Box, CircularProgress, Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useAuthenticated } from "../contexts/AuthenticatedProvider";
import { Outlet } from "react-router-dom";

export interface AuthCheckerProps {}

export function AuthChecker({}: AuthCheckerProps) {
  const { isLoggedIn, loading, error } = useAuthenticated();

  useEffect(() => {
    if (isLoggedIn === false) {
      window.location.replace(
        process.env.NODE_ENV === "development"
          ? "http://localhost:8101/internal/auth/login"
          : "/internal/auth/login"
      );
    }
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return <Outlet />;
  } else {
    return (
      <Stack gap={1}>
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
      </Stack>
    );
  }
}
