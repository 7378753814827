import { FormSpy } from "react-final-form";
import {
  FileFormField,
  RadioFormField,
  TextFormField,
} from "../common/components/FormFields";
import { TypesOfSupport } from "./CfiWinterClothingTypes";
import { Box, Grid, Typography } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";

const dotty = require("dotty");

export interface WinterClothingPurchaseFieldsProps {}

export function WinterClothingPurchaseFields({}: WinterClothingPurchaseFieldsProps) {
  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Purchases</Typography>
      </Box>
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => (
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <RadioFormField
                name="typeOfSupport"
                label="Reimbursement Method"
                options={Object.values(TypesOfSupport)}
              />
            </Grid>
            {dotty.get(values, "typeOfSupport") === TypesOfSupport.BulkPO && (
              <Grid item sm={12}>
                <TextFormField
                  icon={<StoreIcon />}
                  name="vendor"
                  label="Vendor"
                  required
                />
              </Grid>
            )}
          </Grid>
        )}
      />
    </Box>
  );
}
