import { Box, Divider, Grid, useTheme } from "@mui/material";
import React from "react";
import {
  TextFormField,
  RadioFormField,
  DateFormField,
} from "../../common/components/FormFields";
import { MuiCake, MuiPerson, MuiPhone } from "../../common/icons";

export interface ParentFieldsProps {
  index: number;
}

function buildName(name: string, index: number) {
  return `parents[${index}].${name}`;
}

export const ParentFields: React.FC<ParentFieldsProps> = ({
  index,
}: ParentFieldsProps) => {
  const theme = useTheme();

  const required = index == 0;
  return (
    <Box>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={1}>
        <Grid item sm={4} xs={12}>
          <TextFormField
            name={buildName("name", index)}
            label="Biological Parent"
            required={required}
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DateFormField
            name={buildName("dateOfBirth", index)}
            label="Date of Birth"
            required={required}
            icon={<MuiCake />}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextFormField
            name={buildName("phoneNumber", index)}
            label="Phone Number"
            required={required}
            icon={<MuiPhone />}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <TextFormField
            name={buildName("address", index)}
            label="Home Address(Street, Town)"
            required={required}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RadioFormField
            name={buildName("inuvaluit", index)}
            label="Inuvialuit Beneficiary"
            options={["Yes", "No"]}
            required={required}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
