import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DateFormField, TextFormField } from "../common/components/FormFields";
import { MuiPerson } from "../common/icons";
import dayjs from "dayjs";
import { FieldArray } from "react-final-form-arrays";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { CfiWinterClothingChild } from "./CfiWinterClothingTypes";
import { ErrorNotification } from "../common/components/ErrorNotification";
import { useState } from "react";

interface WinterClothingChildFieldsProps {
  namespace: string;
}

function WinterClothingChildFields({
  namespace,
}: WinterClothingChildFieldsProps) {
  const theme = useTheme();

  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Grid container spacing={0.5} flex={1}>
      <Grid item lg={4} xs={12}>
        <TextFormField
          name={`${namespace}.name`}
          label="Name"
          required
          icon={<MuiPerson />}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <DateFormField
          name={`${namespace}.dateOfBirth`}
          label="Date Of Birth"
          required
          stringOutput
          noIcon={lg}
          minDate={dayjs().subtract(20, "years")}
        />
      </Grid>
    </Grid>
  );
}

export const DEFAULT_CHILD_FIELDS: Partial<CfiWinterClothingChild> = {
  name: "",
  idNumber: "",
};

export interface WinterClothingChildrenFieldsProps {}

export function WinterClothingChildrenFields({}: WinterClothingChildrenFieldsProps) {
  const [showError, setShowError] = useState(false);
  return (
    <>
      <Box>
        <Box pb={1}>
          <Typography variant="h5">Children</Typography>
        </Box>
        <FieldArray<Partial<CfiWinterClothingChild>>
          name="children"
          render={({ fields }) => (
            <Stack gap={1}>
              {fields.map((name, index) => (
                <Stack direction="row" alignItems="center" gap={1}>
                  <WinterClothingChildFields namespace={name} />
                  <IconButton
                    color="error"
                    onClick={() => fields.remove(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              ))}
              <Box ml={5}>
                <Button
                  startIcon={<AddIcon />}
                  variant="outlined"
                  onClick={() => {
                    if ((fields?.length ?? 0) >= 10) {
                      setShowError(true);
                    } else {
                      fields.push({ ...DEFAULT_CHILD_FIELDS });
                    }
                  }}
                >
                  Add Child
                </Button>
              </Box>
            </Stack>
          )}
        />
      </Box>
      <ErrorNotification
        open={showError}
        onClose={() => setShowError(false)}
        message="You cannot add more than 10 children to this application"
      />
    </>
  );
}
