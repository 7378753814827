enum Http {
  GET = "get",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
  DELETE = "delete",
}

class HTTPError extends Error {
  readonly status: number;
  readonly statusText: string;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    this.statusText = message;
  }
}

export const sendGet = async <T>(
  url: string,
  params?: string | string[][] | Record<string, any> | URLSearchParams
): Promise<T> => {
  params &&
    Object.keys(params).forEach(
      (key) => (params as any)[key] === undefined && delete (params as any)[key]
    );
  const fetchUrl = params
    ? `${url}?${new URLSearchParams(params).toString()}`
    : url;
  const response = await fetch(fetchUrl);
  return process(response);
};

export const sendPost = async <BodyType, ReturnedType>(
  url: string,
  body?: BodyType
): Promise<ReturnedType> => {
  const response = await fetch(url, {
    method: Http.POST,
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return process(response);
};

export const sendPostForm = async <ReturnedType>(
  url: string,
  body: FormData
): Promise<ReturnedType> => {
  const response = await fetch(url, {
    method: Http.POST,
    body: body,
  });
  return process(response);
};

export const sendFormDataPost = async <U>(
  url: string,
  body?: FormData
): Promise<U> => {
  const response = await fetch(url, {
    method: Http.POST,
    body: body,
  });
  return process(response);
};

export const sendPut = async <T, U>(url: string, body?: T): Promise<U> => {
  const response = await fetch(url, {
    method: Http.PUT,
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return process(response);
};

export const sendDelete = async <T = null>(url: string): Promise<T> => {
  const response = await fetch(url, {
    method: Http.DELETE,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return process(response);
};

async function checkResponse(response: Response) {
  if (!response.ok) {
    let exception;
    let message;
    if (isJson(response)) {
      exception = await response.json();
    }
    message = (exception && exception.message) || response.statusText;
    throw new HTTPError(message, response.status);
  }
}

function isJson(response: Response) {
  const contentType = response.headers.get("content-type");
  if (!contentType) {
    return false;
  }
  return contentType.indexOf("application/json") >= 0;
}

async function process(response: Response) {
  await checkResponse(response);
  if (isJson(response)) {
    return response.json();
  }
  return null;
}
