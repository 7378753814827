import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { TextFormField } from "../index";
import { FormSpy, useField } from "react-final-form";
import { RegionSelector } from "./RegionSelector";
import { AutocompleteFormField } from "../AutocompleteFormField";
import { countries } from "./places";

const dotty = require("dotty");

const OTHER = "Other";

export interface InternationalAddressFieldsProps {
  namespace: string;
  label?: string;
  allowOtherCountries?: boolean;
  required?: boolean;
}

export function InternationalAddressFields({
  namespace,
  required,
  allowOtherCountries,
  label,
}: InternationalAddressFieldsProps) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const {
    input: { value: selectedCountry },
  } = useField<string>(`${namespace}.country`);

  return (
    <Grid container spacing={0.5} pt={1}>
      {label && (
        <Grid item xs={12}>
          <Box ml={5}>
            <Typography>{label}</Typography>
          </Box>
        </Grid>
      )}
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.line1`}
          required={required}
          label="Line 1"
          size="small"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.line2`}
          label="Line 2"
          size="small"
          noIcon={md}
        />
      </Grid>
      <Grid item md={selectedCountry === OTHER ? 4 : 6} xs={12}>
        <AutocompleteFormField
          name={`${namespace}.country`}
          required={required}
          options={countries.concat(allowOtherCountries ? [OTHER] : [])}
          label="Country"
          size="small"
        />
      </Grid>
      {selectedCountry === OTHER && (
        <Grid item md={4} xs={12}>
          <TextFormField
            name={`${namespace}.customCountry`}
            required={required}
            label="Other Country"
            size="small"
            noIcon={md}
          />
        </Grid>
      )}
      <Grid item md={selectedCountry === OTHER ? 4 : 6} xs={12}>
        <TextFormField
          name={`${namespace}.community`}
          required={required}
          label="Community"
          size="small"
          noIcon={md}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormSpy
          subscription={{ values: true }}
          render={({ values }) => (
            <RegionSelector
              namespace={namespace}
              required={required}
              selectedCountry={dotty.get(values, `${namespace}.country`)}
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.postalCode`}
          label="Postal Code"
          required={required}
          size="small"
          noIcon={md}
        />
      </Grid>
    </Grid>
  );
}
