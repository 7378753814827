import { Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { submitAddressChange } from "../common/api/addressChangeApi";
import {
  TextFormField,
  DateFormField,
  RadioFormField,
  AddressFormFields,
} from "../common/components/FormFields";
import { FormFieldWrapper } from "../common/components/FormFields/FormFieldWrapper";
import { useRecaptchaReady } from "../common/hooks/useRecaptchaReady";
import { MuiCake, MuiNumber, MuiPerson } from "../common/icons";
import { AddressChangeSubmission } from "./AddressChangeSubmission";
import { SubmitButton } from "../common/components/SubmitButton";
import { ErrorNotification } from "../common/components/ErrorNotification";

export interface AddressChangeFormProps {}

const hasDependentChildren: string[] = ["Yes", "No"];
const RECAPTCHA_ACTION = "SubmitAddressChangeForm";

export const AddressChangeForm: React.FC<AddressChangeFormProps> = ({}) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const recaptchaReady = useRecaptchaReady();

  const [showError, setShowError] = useState(false);

  return (
    <>
      <Form<AddressChangeSubmission>
        onSubmit={async (addressChange) => {
          try {
            const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
            const token = await grecaptcha.enterprise.execute(clientKey, {
              action: RECAPTCHA_ACTION,
            });
            await submitAddressChange(addressChange, token, RECAPTCHA_ACTION);
            navigate("/success");
          } catch {
            setShowError(true);
          }
        }}
        render={({ handleSubmit, invalid, errors, values, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <TextFormField
                  name="firstName"
                  label="First Name"
                  required
                  icon={<MuiPerson />}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextFormField
                  name="lastName"
                  label="Last Name"
                  required
                  icon={<MuiPerson />}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <DateFormField
                  name="dateOfBirth"
                  label="Date of Birth"
                  required
                  icon={<MuiCake />}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextFormField
                  name="fileNumber"
                  label="File#"
                  required={false}
                  icon={<MuiNumber />}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AddressFormFields
                  name="currentAddress"
                  label="Current Address"
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AddressFormFields
                  name="newAddress"
                  label="New Address"
                  required={true}
                  noIcon={md}
                />
              </Grid>
              <Box ml={2} mt={2}>
                <FormFieldWrapper>
                  <RadioFormField
                    name="dependentsAddressChange"
                    label="Do you have children under 18 that require Address Changes?"
                    options={hasDependentChildren}
                  />
                </FormFieldWrapper>
              </Box>
            </Grid>
            <Box ml={5}>
              <SubmitButton
                type="submit"
                disabled={invalid || !recaptchaReady}
                isSubmitting={submitting}
              />
            </Box>
          </form>
        )}
      />

      <ErrorNotification open={showError} onClose={() => setShowError(false)} />
    </>
  );
};
