import { Box, Button, Stack } from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { submitInquiry } from "../common/api";
import { NestedThemeProvider } from "../common/contexts/NestedThemeProvider";
import { useRecaptchaReady } from "../common/hooks/useRecaptchaReady";
import { Address } from "../common/types";
import { ConsentForm } from "./Consent/ConsentForm";
import { IndividualFields } from "./Fields/IndividualFields";
import { InquirerFields } from "./Fields/InquirerFields";
import { inquiryTheme } from "./inquiryTheme";
import { SubmitButton } from "../common/components/SubmitButton";
import { ErrorNotification } from "../common/components/ErrorNotification";

export interface InquirySubmission {
  inquirerLastName: string;
  inquirerFirstName: string;
  landClaimArea: string;
  beneficiaryNumber: string;
  community: string;
  customCommunity?: string;
  mailingAddress: Address;
  phoneNumber: string;
  emailAddress: string;
  relationshipToIndividual: string;
  preferredMethodOfFollowUp: "email" | "phone";
  inquirerAdditionalInformation?: string;
  individualLastName?: string;
  individualFirstName?: string;
  individualNickName?: string;
  discNumber?: string;
  landClaimAreaOfOrigin?: string;
  communityOfOrigin?: string;
  yearOfBirth?: string;
  gender?: string;
  ageAtHospitalization?: string;
  approximateDateOfHospitalization?: string;
  hospitalIndividualSentTo?: string;
  modeOfTransportation?: string;
  individualAdditionalInformation?: string;
  hasReadConsentForm: boolean;
  releaseConsent: string;
  authorizeConsent: string;
}

const RECAPTCHA_ACTION = "SubmitInquiryForm";

export interface InquiryFormProps {}

export const InquiryForm: React.FC<InquiryFormProps> = ({}) => {
  const recaptchaReady = useRecaptchaReady();
  const navigate = useNavigate();

  const [showError, setShowError] = useState(false);

  return (
    <NestedThemeProvider innerTheme={inquiryTheme}>
      <Form<InquirySubmission>
        onSubmit={async (submission) => {
          try {
            const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
            const token = await grecaptcha.enterprise.execute(clientKey, {
              action: RECAPTCHA_ACTION,
            });

            await submitInquiry(submission, token, RECAPTCHA_ACTION);

            navigate("/success");
          } catch {
            setShowError(true);
          }
        }}
        subscription={{ invalid: true, submitting: true }}
        mutators={{
          readConsentForm: (args, state, utils) => {
            utils.changeValue(state, "hasReadConsentForm", () => true);
          },
        }}
        validate={(values) => {
          const errors: any = {};

          if (!values.hasReadConsentForm) {
            errors.releaseConsent =
              "You must read the above terms to the very bottom before you can consent.";
          }

          if (values.releaseConsent !== "Yes") {
            errors.releaseConsent =
              "You must consent to the release of your information in order to submit this form.";
          }

          if (values.authorizeConsent !== "Yes") {
            errors.authorizeConsent =
              "You must authorize IRC to request information in order to submit this form.";
          }

          return errors;
        }}
        render={({ form, handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Stack gap={3}>
              <InquirerFields />
              <IndividualFields />
              <ConsentForm
                onReadConsent={() => form.mutators.readConsentForm()}
              />
            </Stack>
            <Box ml={5} mt={3}>
              <SubmitButton
                type="submit"
                disabled={invalid || !recaptchaReady}
                isSubmitting={submitting}
              />
            </Box>
          </form>
        )}
      />
      <ErrorNotification open={showError} onClose={() => setShowError(false)} />
    </NestedThemeProvider>
  );
};
