import { Box, Stack, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";
import { MuiCheckCircle } from "../common/icons";

export interface SuccessPageProps {}

export const ApprovedPage: React.FC<SuccessPageProps> = ({}) => {
  return (
    <Stack direction="row" gap={4} alignItems="center" justifyContent="center">
      <Box fontSize={80} lineHeight={1}>
        <MuiCheckCircle color="success" fontSize="inherit" />
      </Box>
      <Typography variant="h5" color={green["900"]}>
        Submission approved
      </Typography>
    </Stack>
  );
};
