import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  InternationalAddressFields,
  TextFormField,
} from "../common/components/FormFields";
import MuiPeople from "@mui/icons-material/People";
import { CanadaAddressFields } from "../common/components/FormFields/CanadaAddressFields";

export interface WinterClothingParentFieldsProps {}

export function WinterClothingParentFields({}: WinterClothingParentFieldsProps) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Parents</Typography>
      </Box>
      <Grid container spacing={0.5}>
        <Grid item md={4} xs={12}>
          <TextFormField
            name="parent1.name"
            label="Name"
            icon={<MuiPeople />}
            required
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextFormField
            name="parent1.contactNumber"
            label="Contact Number"
            required
            noIcon={md}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextFormField
            name="parent1.email"
            label="Email"
            noIcon={md}
            required
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextFormField
            name="parent2.name"
            label="Name"
            icon={<MuiPeople />}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextFormField
            name="parent2.contactNumber"
            label="Contact Number"
            noIcon={md}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextFormField name="parent2.email" label="Email" noIcon={md} />
        </Grid>
        <Grid item xs={12}>
          <CanadaAddressFields required namespace="address" label="Address" />
        </Grid>
      </Grid>
    </Box>
  );
}
