import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useFormState } from "react-final-form";
import {
  CheckboxFormField,
  FileFormField,
  TextFormField,
} from "../../common/components/FormFields";
import { DeepPartial } from "../../common/types";
import { RegistrationSubmission } from "../RegistrationForm";

export interface BirthCertificateAndAdoptionProps {}

export const BirthCertificateAndAdoption: React.FC<
  BirthCertificateAndAdoptionProps
> = ({}) => {
  const { values } = useFormState<DeepPartial<RegistrationSubmission>>();

  return (
    <Box>
      <Typography variant="h6">Birth Certificate</Typography>
      <Grid container>
        <Grid item xs={12}>
          <FileFormField name="birthCertificate" />
        </Grid>
        <Grid item xs={12}>
          <CheckboxFormField
            name="isTheChildAdopted"
            label="Is the child currently in the care of social services, custom adopted or departmentally adopted?"
          />
        </Grid>
        {values.isTheChildAdopted && (
          <Grid item xs={12}>
            <TextFormField
              name="nameOfAdoptionAgency"
              label="Name of CFS agency or person"
              required
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
