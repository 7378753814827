import { Box, FormLabel, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import { TextFormField } from "../common/components/FormFields";
import { MuiAdd } from "../common/icons";

export interface HouseholdMemberListProps {}

function toRange(count: number): number[] {
  return Array.from(new Array(count), (x, i) => i);
}

export interface houseHoldMember {
  firstName?: string;
  lastName?: string;
}

export const HouseholdMemberList: React.FC<HouseholdMemberListProps> = ({}) => {
  const [memberCount, setMemberCount] = useState(2);

  return (
    <Box>
      <Box mb={0.5}>
        <FormLabel>Household Members</FormLabel>
      </Box>
      <Grid container spacing={2} mb={2} alignItems="center">
        {toRange(memberCount).map((i) => (
          <Grid key={i} item xs={6} sm={4}>
            <TextFormField
              name={`houseHoldMembers[${i}].firstName`}
              placeholder="First Name"
              size="small"
              noIcon
            />
            <TextFormField
              name={`houseHoldMembers[${i}].lastName`}
              placeholder="Last Name"
              size="small"
              noIcon
            />
          </Grid>
        ))}
        <Grid item xs={6} sm={4}>
          <IconButton onClick={() => setMemberCount(memberCount + 1)}>
            <MuiAdd />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
