import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";

export interface OtlFormHeaderProps {
  startDate: string;
  endDate: string;
  period: string;
  voucherDate: string;
}

export const OtlFormHeader: React.FC<OtlFormHeaderProps> = ({
  startDate,
  endDate,
  period,
  voucherDate,
}) => {
  return (
    <Box>
      <Typography variant="h5" textTransform="uppercase" mb={2}>
        {period} applications opening for IRC On the Land Support
      </Typography>
      <Typography paragraph>
        {startDate} - Applications open today for {period} On the Land Support
        for Beneficiaries within the Inuvialuit Settlement Region who will be
        traveling on the land this Fall season.
      </Typography>
      <Typography paragraph>
        This is the tenth round of On the Land Support provided by The
        Inuvialuit Regional Corporation over the course of the global COVID-19
        pandemic.
      </Typography>
      <Typography paragraph>
        The deadline for the applications is {endDate} at 5:00PM - MST - (Note:
        Vouchers won't be ready for pick-up until {voucherDate})
      </Typography>
      <Typography>How it works:</Typography>
      <ul>
        <Typography component="li">
          One application per Inuvialuit household
        </Typography>
        <Typography component="li">
          Beneficiaries can now apply here or by submitting a PDF application to{" "}
          <a href="mailto:ontheland@inuvialuit.com">ontheland@inuvialuit.com</a>
        </Typography>
        <Typography component="li">
          Approved households will receive a voucher for $250 gas and $350 for
          groceries
        </Typography>
      </ul>
      <Typography paragraph>
        Please be sure to indicate preferred gas station and grocery store on
        the application. We encourage you to use beneficiary owned and operated
        companies if possible.
      </Typography>
      <Typography paragraph>
        The Inuvialuit Regional Corporation wishes to remind all those traveling
        to be mindful of the weather and safe travels to all.
      </Typography>
    </Box>
  );
};
