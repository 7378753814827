import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Box,
} from "@mui/material";
import React from "react";
import { Field, FieldRenderProps } from "react-final-form";
import { FormFieldWrapperProps, FormFieldWrapper } from "./FormFieldWrapper";

const CheckboxWrapper = (
  props: FieldRenderProps<string | number, HTMLElement, string | number>
) => {
  const {
    input: { checked, value, name, onChange, ...restInput },
  } = props;

  return (
    <Checkbox
      name={name}
      inputProps={restInput}
      onChange={onChange}
      checked={checked}
      value={value}
    />
  );
};

export interface CheckboxFormFieldProps
  extends Omit<FormFieldWrapperProps, "children"> {
  name: string;
  label: string;
  mustBeTrue?: boolean;
}

export const CheckboxFormField: React.FC<CheckboxFormFieldProps> = ({
  name,
  label,
  mustBeTrue,
  ...wrapperProps
}) => {
  return (
    <FormFieldWrapper {...wrapperProps}>
      <Field
        name={name}
        type="checkbox"
        validate={(v) =>
          mustBeTrue && v !== true ? "This must be checked" : undefined
        }
        render={(props) => (
          <>
            <FormControlLabel
              control={
                <FormGroup>
                  <CheckboxWrapper {...props} />
                </FormGroup>
              }
              label={label}
            />
            {props.meta.touched && props.meta.error && (
              <Box p={1}>
                <FormHelperText error>{props.meta.error}</FormHelperText>
              </Box>
            )}
          </>
        )}
      />
    </FormFieldWrapper>
  );
};
