import { Box, Button, Stack } from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { communityApi } from "../common/api";
import { useAsyncData } from "../common/networking";
import { Address } from "../common/types";
import { validate } from "../common/validation";
import { BirthCertificateAndAdoption } from "./Sections/BirthCertificateAndAdoption";
import { ChildInfo } from "./Sections/ChildInfo";
import { ChildPlaceOfBirth } from "./Sections/ChildPlaceOfBirth";
import { ChildPlaceOfResidence } from "./Sections/ChildPlaceOfResidence";
import { FamilyTreeInfo } from "./Sections/FamilyTreeInfo";
import { RegistrantInfo } from "./Sections/RegistrantInfo";
import { RegistrationConsent } from "./Sections/RegistrationConsent";
import { RegistrationFormHeader } from "./Sections/RegistrationFormHeader";
import {
  attachBirthCertificate,
  submitRegistration,
} from "../common/api/registrationApi";
import { useNavigate } from "react-router-dom";
import { useRecaptchaReady } from "../common/hooks/useRecaptchaReady";
import { SubmitButton } from "../common/components/SubmitButton";
import { ErrorNotification } from "../common/components/ErrorNotification";

const RECAPTCHA_ACTION = "SubmitRegistrationForm";

interface FamilyTreeRelation {
  relation: string;
  firstName: string;
  lastName: string;
  inuvialuit: boolean;
}

export interface RegistrationSubmission {
  childFirstName: string;
  childLastName: string;
  childInuvialukName: string;
  gender: string;
  dateOfBirth: Date | string;
  healthCardNumber?: string;
  isTheChildAdopted: boolean;
  nameOfAdoptionAgency?: string;
  haveBirthCertificate: boolean;
  birthCertificate?: File;
  birthCommunity: number | "other";
  outsideISRForMedicalReasons: boolean;
  outsideISRForNonMedicalReasons: boolean;
  comments: string;
  residenceCommunity: number | "other";
  residenceProvince: number | string;
  residenceCountry: string;
  registrantFirstName: string;
  registrantLastName: string;
  registrantRelation: string;
  registrantEmail: string;
  registrantPhoneNumber: string;
  addressCommunity: number | "other";
  address: Address;
  familyTree: FamilyTreeRelation[];
  consentAccept: boolean;
  releaseConsent: boolean;
}

export interface RegistrationFormProps {}

export const RegistrationForm: React.FC<RegistrationFormProps> = ({}) => {
  const { data, isLoading } = useAsyncData(
    () => communityApi.getCommunities(),
    []
  );

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const navigate = useNavigate();
  const recaptchaReady = useRecaptchaReady();

  return (
    <Box>
      <RegistrationFormHeader />
      <Form<RegistrationSubmission>
        onSubmit={async (registration) => {
          const { birthCertificate, ...everythingElse } = registration;
          let id: number = -1;
          try {
            const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
            const token = await grecaptcha.enterprise.execute(clientKey, {
              action: RECAPTCHA_ACTION,
            });

            //Uploading a file requires a FormData object which unfortunately
            // breaks the formatting on the resulting payload, rendering the
            // existing RegistrationSubmission saving useless. We really want to
            // preserve what we have given our time constraints, so instead
            // we split the registration form submission into two api calls
            // one for the submission, and another one to attach the birth
            // certificate file to the existing submission
            id = await submitRegistration(
              everythingElse,
              token,
              RECAPTCHA_ACTION
            );
          } catch {
            setShowError(true);
            setErrorMessage(undefined);
            return;
          }

          try {
            if (birthCertificate) {
              await attachBirthCertificate(birthCertificate, id);
            }

            navigate("/success");
          } catch {
            setShowError(true);
            setErrorMessage(
              "The form was submitted, but the birth certificate failed to upload"
            );
          }
        }}
        validate={(reg) => {
          return {
            address: validate.addressValid(reg.address),
          };
        }}
        subscription={{ invalid: true, submitting: true }}
        render={({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Stack gap={2}>
              <ChildInfo />
              <ChildPlaceOfBirth communities={data} isLoading={isLoading} />
              <ChildPlaceOfResidence />
              <RegistrantInfo />
              <FamilyTreeInfo />
              <BirthCertificateAndAdoption />
              <RegistrationConsent />
            </Stack>

            <Box ml={5} mt={3}>
              <SubmitButton
                type="submit"
                disabled={invalid || !recaptchaReady}
                isSubmitting={submitting}
              />
            </Box>
          </form>
        )}
      />
      <ErrorNotification
        open={showError}
        onClose={() => setShowError(false)}
        message={errorMessage}
      />
    </Box>
  );
};
