import { Box, Typography } from "@mui/material";
import React from "react";

export interface MaligaksatFamilyInfoHeaderProps {}

export const MaligaksatFamilyInfoHeader: React.FC<
  MaligaksatFamilyInfoHeaderProps
> = ({}) => {
  return (
    <Box>
      <Typography variant="h4" textTransform="uppercase" mb={2}>
        MALIGAKSAT FAMILY INFORMATION
      </Typography>
      <Typography paragraph>
        Complete this form if you are providing any service to an Inuvialuit
        child, youth, or family. If there are multiple children in one family
        and the involvement/service is the same for all children, list the
        children on this one form. If the involvement/service is different for a
        sibling, complete a separate form for that sibling. Submit the completed
        form and any changes to the form to:
        <a href="mailto:childadvocate@inuvialuit.com">
          childadvocate@inuvialuit.com
        </a>
      </Typography>
    </Box>
  );
};
