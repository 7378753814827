import { sendPost } from "../networking/api";
import { apiResponse } from "./Interfaces/response";

export interface MaligaksatFamilyInfoSubmission {
  children: Child[];
  parents: Parent[];
  sharedWithFamily: string;
  legalGuardian?: {
    name: string;
    phoneNumber: string;
    address: string;
  };
  careGiver?: {
    name: string;
    phoneNumber: string;
    address: string;
  };
  parentLawyers?: Lawyer[];
  childLawyer?: Lawyer;
  agencyLawyer?: Lawyer;
  otherFamily: string;
  otherSupports: string;
  workerName: string;
  agency: string;
  agencyPhoneNumber: string;
  fileStatus: string;
  fileProtections: string;
  supportsOffered: string;
  agreement: string;
}

export interface Child {
  name: string;
  dateOfBirth: string;
  gender: string;
  affiliated: string;
  otherIndigenousOrgInfo?: string;
  NIHBNumber: string;
  livingWith: string;
  phoneNumber: string;
  homeAddress: string;
}

interface Parent {
  name?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  address?: string;
  inuvaluit?: string;
}

interface Lawyer {
  name: string;
  contactInfo: string;
}

export async function submitMaligaksatFamilyInfo(
  maligaksatFamilyInformation: MaligaksatFamilyInfoSubmission,
  recaptchaToken: string,
  recaptchaAction: string
): Promise<apiResponse> {
  return await sendPost("/api/maligaksatFamilyInformation/add", {
    maligaksatFamilyInformation,
    recaptchaToken,
    recaptchaAction,
  });
}
