import {RadioFormFieldOption} from "../common/components/FormFields";
import {ListOption} from "../common/api/Interfaces/listOption";

export const OTHER_GROCERY_STORE = { value: "4", text: "Other" }
export const OTHER_GAS_STORE = { value: "7", text: "Other" };

export function getGroceryStoreOptionsForCommunity(communities: ListOption[]|undefined, communityId: string): RadioFormFieldOption[] {
  const communityName = communities?.find(community => community.id === parseInt(communityId))?.name;

  const STANTON_DISTRIBUTING = { value: "1", text: "Stanton Distributing" }
  const CO_OP = { value: "2", text: "Co-op" }
  const NORTHERN = { value: "3", text: "Northern" }
  const IKAHUK_CO_OP = { value: "5", text: "Ikahuk Co-op" }
  const HOLMAN_CO_OP = { value: "6", text: "Holman Co-op" }

  const INUVIK_AND_DEFAULT_GROCERY_STORES = [
    STANTON_DISTRIBUTING,
    NORTHERN
  ];

  let result = [];
  switch (communityName) {
    case "Aklavik":
      result = [
        STANTON_DISTRIBUTING,
        NORTHERN
      ];
      break;
    case "Inuvik":
      result = INUVIK_AND_DEFAULT_GROCERY_STORES
      break;
    case "Paulatuk":
      result = [
        STANTON_DISTRIBUTING,
        NORTHERN
      ]
      break;
    case "Sachs Harbour":
      result = [
        STANTON_DISTRIBUTING,
        IKAHUK_CO_OP
      ]
      break;
    case "Tuktoyaktuk":
      result = [
        STANTON_DISTRIBUTING,
        NORTHERN
      ]
      break;
    case "Ulukhaktok":
      result = [
        NORTHERN,
        HOLMAN_CO_OP
      ]
      break;
    default:
      result = INUVIK_AND_DEFAULT_GROCERY_STORES
      break;
  }

  return result.concat(OTHER_GROCERY_STORE);
}

export function getGasStoreOptionsForCommunity(communities: ListOption[]|undefined, communityId: string): RadioFormFieldOption[] {
  const communityName = communities?.find(community => community.id === parseInt(communityId))?.name;

  const NORTHWIND_PETROLEUM = { value: "1", text: "Northwind Petroleum" };
  const BOBS_GAS_BAR = { value: "2", text: "Bob's Gas Bar" };
  const CO_OP = { value: "3", text: "Co-op" };
  const NORTHERN = { value: "4", text: "Northern" };
  const ATBA = { value: "5", text: "ATBA" };
  const EGT_PETROLEUM = { value: "6", text: "EGT Petroleum" };
  const ARCTIC_PETROLEUM_SERVICES = { value: "8", text: "Arctic Petroleum Services" };
  const IKAHUK_CO_OP = { value: "9", text: "Ikahuk Co-op" }
  const HOLMAN_CO_OP = { value: "10", text: "Holman Co-op" }

  const INUVIK_AND_DEFAULT_GAS_STORES = [
    ARCTIC_PETROLEUM_SERVICES,
    BOBS_GAS_BAR,
    NORTHWIND_PETROLEUM
  ];

  let result = [];
  switch (communityName) {
    case "Aklavik":
      result = [
        ARCTIC_PETROLEUM_SERVICES,
        BOBS_GAS_BAR
      ];
      break;
    case "Inuvik":
      result = INUVIK_AND_DEFAULT_GAS_STORES
      break;
    case "Paulatuk":
      return [
        ATBA
      ]
    case "Sachs Harbour":
      result = [
        IKAHUK_CO_OP
      ]
      break;
    case "Tuktoyaktuk":
      result = [
        BOBS_GAS_BAR,
        EGT_PETROLEUM
      ]
      break;
    case "Ulukhaktok":
      result = [
        HOLMAN_CO_OP
      ]
      break;
    default:
      result = INUVIK_AND_DEFAULT_GAS_STORES
      break;
  }

  return result.concat(OTHER_GAS_STORE);
}
