import { TravelRequestSubmission } from "../../TravelRequest/TravelRequestSubmission";
import { sendGet, sendPost } from "../networking/api";
import { apiResponse } from "./Interfaces/response";

export async function submitTravelRequest(
  travelRequest: TravelRequestSubmission,
  recaptchaToken: string,
  recaptchaAction: string
): Promise<apiResponse> {
  return await sendPost("/internal/api/travelRequest/add", {
    travelRequest,
    recaptchaToken,
    recaptchaAction,
  });
}

export async function updateTravelRequest(
  id: number,
  travelRequest: TravelRequestSubmission
): Promise<apiResponse> {
  return await sendPost(
    `/internal/api/travelRequest/update/${id}`,
    travelRequest
  );
}

export async function getTravelRequest(id: number) {
  return await sendGet<{
    travelRequest: TravelRequestSubmission;
    canEdit: boolean;
    canSeeEstimate: boolean;
    canEstimate: boolean;
    canApprove: boolean;
    isApproved: boolean
  }>(`/internal/api/travelRequest/get/${id}`);
}

export async function approveRequest(id: number) {
  return await sendPost(`/internal/api/travelRequest/approve/${id}`);
}
