import React from "react";
import { ErrorMessage } from "../common/components/ErrorMessage";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import QuestionIcon from "@mui/icons-material/HelpOutline";

export interface TravelRequestReviewErrorProps {
  error: Error;
}

export function TravelRequestReviewError({
  error,
}: TravelRequestReviewErrorProps) {
  if ("status" in error) {
    const statusError = (error as any).status;

    if (statusError === 403) {
      return (
        <ErrorMessage
          icon={<WorkOffIcon fontSize="inherit" />}
          message="You do not have access to this travel request submission"
        />
      );
    }

    if (statusError === 404) {
      return (
        <ErrorMessage
          icon={<QuestionIcon fontSize="inherit" />}
          message="This travel request submission could not be found"
        />
      );
    }
  }

  return <ErrorMessage />;
}
