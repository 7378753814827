import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  RadioFormField,
  SelectFormField,
  TextFormField,
} from "../../common/components/FormFields";
import { useFormState } from "react-final-form";
import { ProjectJewelFormResult } from "../projectJewel";
import { MuiEmail, MuiMail, MuiPhone } from "../../common/icons";
import { useAsyncData } from "../../common/networking";
import { communityApi } from "../../common/api";

const OTHER = "Other";

export interface EthnicitySectionProps {}

export function ContactInformation({}: EthnicitySectionProps) {
  const { values } = useFormState<ProjectJewelFormResult>();

  const { data: communities } = useAsyncData(
    () => communityApi.getCommunities(),
    []
  );

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextFormField
            icon={<MuiMail />}
            name="poBoxNumber"
            label="PO Box Number"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField name="email" label="Email" required noIcon={sm} />
        </Grid>
        <Grid item md={values.community === OTHER ? 6 : 12} xs={12}>
          <SelectFormField
            name="community"
            label="Current Home Community"
            required
            options={
              communities ? [...communities?.map((a) => a.name), OTHER] : []
            }
          />
        </Grid>
        {values.community === OTHER && (
          <Grid item md={6} xs={12}>
            <TextFormField
              name="customCommunity"
              label="Custom Community"
              noIcon={sm}
              required
            />
          </Grid>
        )}

        <Grid item sm={6} xs={12}>
          <TextFormField
            icon={<MuiPhone />}
            name="primaryPhoneNumber"
            label="Primary Phone Number"
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="otherPhoneNumber"
            label="Other Phone Number"
            noIcon={sm}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="emergencyContactName"
            label="Emergency Contact Name"
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="emergencyContactPhone"
            label="Emergency Contact Phone"
            required
            noIcon={sm}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
