import {
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { communityApi, getOtlSettings } from "../common/api";
import { submitOTL } from "../common/api/otlAPi";
import {
  TextFormField,
  SelectFormField,
  SelectFormFieldOption,
  DateFormField,
  RadioFormField,
  AddressFormFields,
  RadioFormFieldOption,
} from "../common/components/FormFields";
import { FormFieldWrapper } from "../common/components/FormFields/FormFieldWrapper";
import {
  MuiCake,
  MuiEmail,
  MuiHome,
  MuiLocalGasStation,
  MuiLocalGroceryStore,
  MuiMap,
  MuiNumber,
  MuiPerson,
  MuiPhone,
} from "../common/icons";
import { sendGet, useAsyncData } from "../common/networking";
import { Address } from "../common/types";
import { houseHoldMember, HouseholdMemberList } from "./HouseholdMemberList";
import { OtlFormHeader } from "./OtlFormHeader";
import { OtlFormSubmission } from "./OtlFormSubmission";
import { ErrorNotification } from "../common/components/ErrorNotification";
import {
  getGasStoreOptionsForCommunity,
  getGroceryStoreOptionsForCommunity,
  OTHER_GAS_STORE,
  OTHER_GROCERY_STORE,
} from "./storeFetcher";

export interface OtlFormProps {}

export const OtlForm: React.FC<OtlFormProps> = ({}) => {
  const { data: communities, isLoading: isCommunitiesLoading } = useAsyncData(
    () => communityApi.getCommunities(),
    []
  );

  const { data: otlSettings, isLoading } = useAsyncData(
    () => getOtlSettings(),
    []
  );

  const navigate = useNavigate();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const [showError, setShowError] = useState(false);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (otlSettings?.enabled) {
    return (
      <Box>
        <Box mb={2}>
          <OtlFormHeader {...otlSettings} />
        </Box>
        <Form<OtlFormSubmission>
          onSubmit={async (otl) => {
            try {
              submitOTL(otl);
              navigate("/success");
            } catch {
              setShowError(true);
            }
          }}
          render={({ handleSubmit, invalid, values }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={12} md={true}>
                  <TextFormField
                    name="firstName"
                    label="First Name"
                    required
                    icon={<MuiPerson />}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={true}>
                  <TextFormField
                    name="lastName"
                    label="Last Name"
                    required
                    noIcon
                  />
                </Grid>
                <Grid item xs={12} sm={true}>
                  <SelectFormField
                    name="community"
                    label="Community"
                    required
                    icon={<MuiMap />}
                    isLoading={isCommunitiesLoading}
                    options={communities
                      ?.map(
                        (d): SelectFormFieldOption => ({
                          value: d.id,
                          text: d.name,
                        })
                      )
                      .concat({ value: "other", text: "Other" })}
                  />
                </Grid>
                {values.community === "other" && (
                  <Grid item xs={12} sm={true}>
                    <TextFormField
                      name="customCommunity"
                      label="Custom Community"
                      required
                      noIcon={sm}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <TextFormField
                    name="fileNumber"
                    label="File#"
                    required={false}
                    icon={<MuiNumber />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateFormField
                    name="dateOfBirth"
                    label="Date of Birth"
                    required
                    icon={<MuiCake />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFormField
                    name="email"
                    label="Email"
                    required
                    icon={<MuiEmail />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFormField
                    name="phoneNumber"
                    label="Phone"
                    required
                    icon={<MuiPhone />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFormField
                    name="campLocation"
                    label="Camp Location"
                    required
                    icon={<MuiHome />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AddressFormFields
                    name="physicalAddress"
                    label="Physical Address"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AddressFormFields
                    name="mailingAddress"
                    label="Mailing Address"
                    noIcon={md}
                  />
                </Grid>
              </Grid>
              <FormFieldWrapper>
                <HouseholdMemberList />
              </FormFieldWrapper>

              <Box mt={2}>
                <RadioFormField
                  name="gasStorePreference"
                  label="Gas Store Preference"
                  icon={<MuiLocalGasStation />}
                  options={getGasStoreOptionsForCommunity(
                    communities,
                    values.community
                  )}
                />
                {values.gasStorePreference === OTHER_GAS_STORE.value && (
                  <TextFormField
                    name="customGasStore"
                    label="Custom Gas Store"
                  />
                )}
              </Box>
              <Box mt={2}>
                <RadioFormField
                  name="groceryStorePreference"
                  label="Grocery Store Preference"
                  icon={<MuiLocalGroceryStore />}
                  options={getGroceryStoreOptionsForCommunity(
                    communities,
                    values.community
                  )}
                />
                {values.groceryStorePreference ===
                  OTHER_GROCERY_STORE.value && (
                  <TextFormField
                    name="customGroceryStore"
                    label="Custom Grocery Store"
                  />
                )}
              </Box>

              <Box ml={5} mt={2}>
                <FormLabel>Emergency Contact</FormLabel>
              </Box>
              <Grid container mb={4} columnSpacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextFormField
                    name="emergencyContact.firstName"
                    label="First Name"
                    icon={<MuiPerson />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextFormField
                    name="emergencyContact.lastName"
                    label="Last Name"
                    noIcon
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextFormField
                    name="emergencyContact.phoneNumber"
                    label="Phone Number"
                    noIcon={sm}
                  />
                </Grid>
              </Grid>

              <Box ml={5}>
                <Button
                  type="submit"
                  disabled={invalid}
                  variant="contained"
                  size="large"
                >
                  Submit
                </Button>
              </Box>
            </form>
          )}
        />
        <ErrorNotification
          open={showError}
          onClose={() => setShowError(false)}
        />
      </Box>
    );
  } else {
    return (
      <Typography fontSize={24} textAlign={"center"}>
        The OTL funding is currently closed. Stay tuned for future updates.
      </Typography>
    );
  }
};
