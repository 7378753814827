import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { Field, FieldRenderProps, useFormState } from "react-final-form";
import { deepIndex } from "../../deepIndex";
import { FormFieldWrapper, FormFieldWrapperProps } from "./FormFieldWrapper";

export interface RadioFormFieldOption {
  value: string | number;
  text?: string | number;
}

//Courtesy of https://stackoverflow.com/questions/58099124/how-to-use-custom-radio-component-with-react-final-form
const RadioWrapper = (
  props: FieldRenderProps<string | number, HTMLElement, string | number> & {
    disabled?: boolean;
  },
  required: boolean
) => {
  const {
    disabled,
    input: { checked, value, name, onChange, ...restInput },
  } = props;

  return (
    <Radio
      name={name}
      inputProps={restInput}
      onChange={onChange}
      checked={checked}
      value={value}
      required={required}
      disabled={disabled}
    />
  );
};

export interface RadioFormFieldProps
  extends Omit<FormFieldWrapperProps, "children"> {
  name: string;
  label?: string;
  options: RadioFormFieldOption[] | string[];
  required?: boolean;
  disabled?: boolean;
}

export const RadioFormField: React.FC<RadioFormFieldProps> = ({
  name,
  label,
  options,
  required,
  disabled,
  ...wrapperProps
}) => {
  const { errors, visited } = useFormState({
    subscription: { errors: true, visited: true },
  });
  const targetError = deepIndex(errors, name);
  const targetVisited = deepIndex(visited, name);

  return (
    <FormFieldWrapper {...wrapperProps}>
      <FormControl required={required}>
        {label && (
          <FormLabel id={`${name}-radio-buttons-group-label`}>
            {label}
          </FormLabel>
        )}
        <RadioGroup
          aria-labelledby={`${name}-radio-buttons-group-label`}
          row
          name={name}
        >
          {options.map((o) => (
            <FormControlLabel
              label={typeof o === "string" ? o : o.text ?? o.value}
              control={
                <Field
                  name={name}
                  value={typeof o === "string" ? o : o.value}
                  type="radio"
                  validate={(v) =>
                    required && v == null ? "This field is required" : undefined
                  }
                  render={({ input, meta }) => (
                    <RadioWrapper
                      input={input}
                      meta={meta}
                      disabled={disabled}
                      required={required}
                    />
                  )}
                  required={required}
                />
              }
            />
          ))}
        </RadioGroup>
        {(targetVisited || disabled) && targetError && (
          <FormHelperText error>{targetError}</FormHelperText>
        )}
      </FormControl>
    </FormFieldWrapper>
  );
};
