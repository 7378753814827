import { Alert, Snackbar } from "@mui/material";
import React from "react";

export interface ErrorNotificationProps {
  open?: boolean;
  message?: string;
  onClose?: () => void;
}

export const ErrorNotification: React.FC<ErrorNotificationProps> = ({
  open,
  message,
  onClose,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={10000} onClose={onClose}>
      <Alert
        onClose={onClose}
        severity="error"
        sx={{ width: "100%" }}
        elevation={6}
        variant="filled"
      >
        {message ?? "An error occurred while submitting the form"}
      </Alert>
    </Snackbar>
  );
};
