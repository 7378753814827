import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import {
  CdcAllergy,
  CdcFoodRequirement,
  CdcFormResult,
  CdcIndividual,
} from "./CdcTypes";
import { ErrorNotification } from "../common/components/ErrorNotification";
import { useState } from "react";
import { Box, Stack } from "@mui/material";
import { CdcHeader } from "./CdcHeader";
import { SubmitButton } from "../common/components/SubmitButton";
import { useRecaptchaReady } from "../common/hooks/useRecaptchaReady";
import { useNavigate } from "react-router-dom";
import { CdcApplicantFields } from "./CdcApplicantFields";
import { CdcParentGuardianFields } from "./CdcParentGuardianFields";
import { CdcEmergencyContactFields } from "./CdcEmergencyContactFields";
import { CdcIndividualsFields } from "./CdcIndividualsFields";
import { CdcHealthCareFields } from "./CdcHealthCareFields";
import { DEFAULT_ALLERGY_FIELDS } from "./CdcAllergyFields";
import { DEFAULT_FOOD_REQUIREMENT_FIELDS } from "./CdcFoodRequirementsFields";
import { CdcSpecialPermissionFields } from "./CdcSpecialPermissionFields";
import { submitCdcApplication } from "../common/api/cdcApplicationApi";
import { CdcLiabilityFields } from "./CdcLiabilityFields";
import { CdcConsentAndDeclarationFields } from "./CdcConsentAndDeclarationFields";

const RECAPTCHA_ACTION = "SubmitCdcApplication";

const initialValues: Partial<CdcFormResult> = {
  healthCareInfo: {
    allergyList: [{ ...(DEFAULT_ALLERGY_FIELDS as CdcAllergy) }],
    foodRequirementsList: [
      { ...(DEFAULT_FOOD_REQUIREMENT_FIELDS as CdcFoodRequirement) },
    ],
    hasMedicalRecords: false,
    medicalRecords: [],
  },
  specialPermissions: {
    hasExursionPermission: false,
    hasTransportationPermission: false,
  },
  restrictedIndividuals: [],
};

export interface CdcFormProps {}

export function CdcForm({}: CdcFormProps) {
  const recaptchaReady = useRecaptchaReady();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const submit = async (result: CdcFormResult) => {
    try {
      const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
      const token = await grecaptcha.enterprise.execute(clientKey, {
        action: RECAPTCHA_ACTION,
      });

      await submitCdcApplication(result, token, RECAPTCHA_ACTION);

      navigate("/success");
    } catch {
      setShowError(true);
    }
  };

  return (
    <>
      <Form<CdcFormResult>
        onSubmit={submit}
        subscription={{ submitting: true }}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Stack gap={2}>
              <CdcHeader />
              <CdcApplicantFields />
              <CdcParentGuardianFields />
              <CdcEmergencyContactFields />
              <CdcIndividualsFields
                label={"Individuals To Whom The Child May Be Released"}
                namespace={"approvedIndividuals"}
              />
              <CdcIndividualsFields
                label={
                  "Individuals Restricted/Prohibited From Access To The Child"
                }
                disclaimer={
                  "If applicable: The name of any parent or other person who, by court order or agreement, is restricted in or prohibited from exercising access to the child or from pick up the child. Please attach a copy of the applicable court order or agreement."
                }
                namespace={"restrictedIndividuals"}
                includeFiles
              />
              <CdcHealthCareFields />
              <CdcSpecialPermissionFields />
              <CdcConsentAndDeclarationFields />
              <CdcLiabilityFields />
              <Box ml={5} mt={3}>
                <SubmitButton
                  type="submit"
                  disabled={!recaptchaReady}
                  isSubmitting={submitting}
                />
              </Box>
            </Stack>
          </form>
        )}
      />
      <ErrorNotification open={showError} onClose={() => setShowError(false)} />
    </>
  );
}
