import { Grid, Stack, Typography } from "@mui/material";
import { TextFormField } from "../common/components/FormFields";
import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export interface EstimateCostProps {
  name: string;
  disabled?: boolean;
  canSeeEstimate?: boolean;
  canEstimate?: boolean;
}

export interface EstimatedCost {
  cost?: string;
  description?: string;
}

export const EstimatedCostFields: React.FC<EstimateCostProps> = ({
  name,
  disabled,
  canSeeEstimate,
  canEstimate,
}) => {
  if (!canSeeEstimate) {
    return null;
  }

  return (
    <>
      <Typography fontSize={18} fontWeight="bold">
        Travel Department Estimations
      </Typography>
      <Stack direction="row">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextFormField
              name={`${name}.estimatedFlightCost`}
              label="Estimated Flight Cost"
              size="medium"
              disabled={disabled || !canEstimate}
              icon={<AttachMoneyIcon />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFormField
              name={`${name}.estimatedHotelCost`}
              label="Estimated Hotel Cost"
              size="medium"
              disabled={disabled || !canEstimate}
              noIcon
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
