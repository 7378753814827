import { ListOption } from "./Interfaces/listOption";

export function getCommunities(): Promise<ListOption[]> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        { id: 1, name: "Inuvik" },
        { id: 2, name: "Sachs Harbour" },
        { id: 3, name: "Tuktoyaktuk" },
        { id: 4, name: "Aklavik" },
        { id: 5, name: "Paulatuk" },
        { id: 6, name: "Ulukhaktok" },
      ]);
      // TODO this should be an API call to get the communities, but hardcoded on the frontend for now. We can simulate an API call here so the change won't be different to the end user.
    }, Math.random() * 300 + 150);
  });
}
