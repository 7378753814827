import { Typography, Grid, Box } from "@mui/material";
import React from "react";
import { useFormState } from "react-final-form";
import {
  TextFormField,
  SelectFormField,
  SelectFormFieldOption,
} from "../../common/components/FormFields";
import { DeepPartial } from "../../common/types";
import { RegistrationSubmission } from "../RegistrationForm";

export interface ChildPlaceOfResidenceProps {}

const provincesOrTerritories: SelectFormFieldOption[] = [
  { value: 1,  text: "British Columbia" },
  { value: 2,  text: "Alberta" },
  { value: 3,  text: "Saskatchewan" },
  { value: 4,  text: "Manitoba" },
  { value: 5,  text: "Ontario" },
  { value: 6,  text: "Quebec" },
  { value: 7,  text: "Newfoundland and Labrador" },
  { value: 8,  text: "Prince Edward Island" },
  { value: 9,  text: "Nova Scotia" },
  { value: 10, text:  "New Brunswick" },
  { value: 11, text:  "Yukon" },
  { value: 12, text:  "Northwest Territories" },
  { value: 13, text:  "Nunavut" },
  { value: 14, text:  "Other" },
];

export const ChildPlaceOfResidence: React.FC<
  ChildPlaceOfResidenceProps
> = ({}) => {
  const { values } = useFormState<DeepPartial<RegistrationSubmission>>();

  return (
    <Box>
      <Typography variant="h6">Place of Residence</Typography>
      <Grid container rowSpacing={2} mt={-1}>
        <Grid item xs={12} sm={6}>
          <TextFormField
            name="residenceCommunity"
            label="Community"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectFormField
            name="residenceProvince"
            label="Province/Territory"
            options={provincesOrTerritories}
            required
          />
        </Grid>
        {values?.residenceCommunity === "other" && (
          <>
            <Grid item xs={12} sm={6}>
              <TextFormField
                name="residenceCountry"
                label="Country"
                required
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
