import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { TextFormField } from "../index";
import { FormSpy, useField } from "react-final-form";
import { AutocompleteFormField } from "../AutocompleteFormField";
import { provincesAndTerritories } from "./places";

export interface CanadaAddressFieldsProps {
  namespace: string;
  label?: string;
  required?: boolean;
}

export function CanadaAddressFields({
  namespace,
  required,
  label,
}: CanadaAddressFieldsProps) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const {
    input: { value: selectedCountry },
  } = useField<string>(`${namespace}.country`);

  return (
    <Grid container spacing={0.5} pt={1}>
      {label && (
        <Grid item xs={12}>
          <Box ml={5}>
            <Typography>{label}</Typography>
          </Box>
        </Grid>
      )}
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.line1`}
          required={required}
          label="Line 1"
          size="small"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.line2`}
          label="Line 2"
          size="small"
          noIcon={md}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.country`}
          disabled
          required={required}
          value={"Canada"}
          label="Country"
          size="small"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.community`}
          required={required}
          label="Community"
          size="small"
          noIcon={md}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormSpy
          subscription={{ values: true }}
          render={({ values }) => (
            <AutocompleteFormField
              name={`${namespace}.region`}
              required={required}
              options={provincesAndTerritories}
              label={"Province/Territory"}
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.postalCode`}
          label="Postal Code"
          required={required}
          size="small"
          noIcon={md}
        />
      </Grid>
    </Grid>
  );
}
