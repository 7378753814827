import { Box, Button, Popover, Stack, styled, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { MuiLink } from "../common/icons";
import { externalForms, internalForms } from "../router";
import { Link } from "react-router-dom";

interface MenuLabelProps {
  children: React.ReactNode;
}

function MenuLabel({ children }: MenuLabelProps) {
  return (
    <Box pl={2} py={0.5} bgcolor="#eee">
      <Typography variant="caption" textTransform="uppercase" color="#444">
        {children}
      </Typography>
    </Box>
  );
}

const UnstyledLink = styled(Link)({
  color: "unset",
  textDecoration: "none",
});

export interface DevLinksProps {}

export function DevLinks({}: DevLinksProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  if (process.env.NODE_ENV !== "development") {
    return null;
  }

  return (
    <>
      <Button
        ref={buttonRef}
        startIcon={<MuiLink />}
        sx={{ color: "white" }}
        onClick={() => setOpen(true)}
      >
        DEV LINKS
      </Button>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack>
          <MenuLabel>Public</MenuLabel>
          {externalForms.map((f) => (
            <UnstyledLink key={f.path} to={f.path}>
              <Button onClick={() => setOpen(false)} fullWidth size="large">
                {f.name}
              </Button>
            </UnstyledLink>
          ))}
          <MenuLabel>Internal</MenuLabel>
          {internalForms.map((f) => (
            <UnstyledLink key={f.path} to={`/internal/${f.path}`}>
              <Button onClick={() => setOpen(false)} fullWidth size="large">
                {f.name}
              </Button>
            </UnstyledLink>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
