import { useEffect, useRef } from "react";

export function useWindowDragFileListener(
  onDragEvent: (dragging: boolean) => void
) {
  const dragCountRef = useRef(0);

  useEffect(() => {
    function dragEnter() {
      if (dragCountRef.current === 0) {
        onDragEvent(true);
      }

      dragCountRef.current++;
    }

    function dragOver(e: DragEvent) {
      e.preventDefault();
      e.stopPropagation();
    }

    function dragLeave() {
      dragCountRef.current--;

      if (dragCountRef.current === 0) {
        onDragEvent(false);
      }
    }

    window.addEventListener("dragenter", dragEnter);
    window.addEventListener("dragover", dragOver);
    window.addEventListener("dragleave", dragLeave);

    return () => {
      window.removeEventListener("dragenter", dragEnter);
      window.removeEventListener("dragover", dragOver);
      window.removeEventListener("dragleave", dragLeave);
    };
  }, [onDragEvent]);
}
