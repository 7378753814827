import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  AddressFormFields,
  CheckboxFormField,
  InternationalAddressFields,
  TextFormField,
} from "../common/components/FormFields";
import { MuiPerson } from "../common/icons";
import { CdcContactFields } from "./CdcContactFields";

export interface CdcEmergencyContactFieldProps {}

export function CdcEmergencyContactFields({}: CdcEmergencyContactFieldProps) {
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const namespace = "emergencyContact";

  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Emergency Contact Information</Typography>
      </Box>
      <Grid container spacing={0.5} flex={1}>
        <Grid item lg={3} sm={6} xs={12}>
          <TextFormField
            name={`${namespace}.lastName`}
            label="Last Name"
            required
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <TextFormField
            name={`${namespace}.firstName`}
            label="First Name"
            required
            noIcon={sm}
          />
        </Grid>
        <Grid item xs={12}>
          <AddressFormFields
            name={`${namespace}.homeAddress`}
            required
            label="Home Address"
          />
        </Grid>
        <Grid item xs={12}>
          <AddressFormFields
            name={`${namespace}.workAddress`}
            label="Work Address"
          />
        </Grid>
        <Grid item xs={12}>
          <CdcContactFields namespace={namespace} />
        </Grid>
        <Grid item xs={12}>
          <Box py={2}>
            <CheckboxFormField
              name="confirmDifferentEmergencyContact"
              label="I confirm that this individual does not have the same home address as the one entered above"
              mustBeTrue
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
