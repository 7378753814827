import React from "react";
import { Stack } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import {
  DateFormField,
  RadioFormField,
  SelectFormField,
  TextFormField,
} from "../common/components/FormFields";
import { EstimatedCostFields } from "./EstimateCost";
import { TravelRequestSubmission } from "./TravelRequestSubmission";
import { useFormState } from "react-final-form";
import dayjs from "dayjs";

export interface TripSegmentFieldsProps {
  name: string;
  index: number;
  disabled?: boolean;
  canSeeEstimate?: boolean;
  canEstimate?: boolean;
}

export function TripSegmentFields({
  name,
  index,
  disabled,
  canEstimate,
  canSeeEstimate,
}: TripSegmentFieldsProps) {
  const {
    values: { tripSegments },
  } = useFormState<TravelRequestSubmission>({ subscription: { values: true } });

  return (
    <Stack key={index}>
      <Typography variant="h6" paragraph>
        Segment {index + 1}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <TextFormField
            name={`${name}.startingLocation`}
            label="Starting Location"
            size="medium"
            disabled={disabled}
            noIcon
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFormField
            name={`${name}.destination`}
            label="Destination"
            size="medium"
            disabled={disabled}
            noIcon
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFormField
            name={`${name}.numberOfDays`}
            label="Number of Days of Stay"
            size="medium"
            disabled={disabled}
            noIcon
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DateFormField
            name={`${name}.dateOfDeparture`}
            label="Departure Date"
            disabled={disabled}
            noIcon
            minDate={
              index > 0
                ? dayjs(tripSegments?.at(index - 1)?.dateOfDeparture)
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectFormField
            name={`${name}.preferredDepartureTime`}
            label="Preferred Departure Time"
            size="medium"
            disabled={disabled}
            noIcon
            options={[
              { value: "am", text: "am" },
              { value: "pm", text: "pm" },
            ]}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <RadioFormField
            name={`${name}.rentalRequired`}
            label="Rental Required"
            options={["Yes", "No"]}
            noIcon
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <RadioFormField
            name={`${name}.flightRequired`}
            label="Flight Required"
            options={["Yes", "No"]}
            noIcon
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <RadioFormField
            name={`${name}.hotelRequired`}
            label="Hotel Required"
            options={["Yes", "No"]}
            noIcon
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <EstimatedCostFields
            name={name}
            disabled={disabled}
            canSeeEstimate={canSeeEstimate}
            canEstimate={canEstimate}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
