import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Form } from "react-final-form";
import { ErrorNotification } from "../common/components/ErrorNotification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { RegistrantInfo } from "./RegistrantInfo";
import { DEFAULT_HOUSEHOLD_VALUES, Household } from "./Household";
import { HouseholdEquipment } from "./CurrentHouseholdEquipment";
import { RequestedEquipment } from "./RequestedHouseholdEquipment";
import {
  TextFormField,
  FileFormField,
  CheckboxFormField,
} from "../common/components/FormFields";
import { MuiComment } from "../common/icons";
import { getIHAPSettings, submitIHAP } from "./IHAPApi";
import { RequestedEquipmentSum } from "./RequestEquipmentSum";
import { confirmCosts } from "./IHapFormValidator";
import arrayMutators from "final-form-arrays";
import { useAsyncData } from "../common/networking";
import { BeneficiaryInfo } from "./BeneficiaryInfo";

const RECAPTCHA_ACTION = "SubmitIHAPForm";

export interface IHAPFormSubmission {
  beneficiary: boolean;
  beneficiaryFileNumber: string;
  registrantFirstName: string;
  registrantLastName: string;
  line1: string;
  line2: string;
  country: string;
  customCountry: string;
  community: string;
  region: string;
  postalCode: string;
  dateOfBirth: Date;
  household: Household;
  householdEquipment: HouseholdEquipment[];
  minorRequestedEquipment: RequestedEquipment[];
  majorRequestedEquipment: RequestedEquipment[];
  comments: string;
  additionalDocuments: File[];
}

const initialValues: Partial<IHAPFormSubmission> = {
  household: DEFAULT_HOUSEHOLD_VALUES,
};

export interface IHAPFormProps {}

export const IHAPForm: React.FC<IHAPFormProps> = ({}) => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();

  const { data: ihapSettings, isLoading } = useAsyncData(
    () => getIHAPSettings(),
    []
  );

  if (ihapSettings?.enabled) {
    return (
      <Box>
        <Typography variant="h4" paragraph>
          IHAP Application Form
        </Typography>
        <Form<IHAPFormSubmission>
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          onSubmit={async (ihap) => {
            try {
              const clientKey =
                process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
              const token = await grecaptcha.enterprise.execute(clientKey, {
                action: RECAPTCHA_ACTION,
              });

              await submitIHAP(ihap, token, RECAPTCHA_ACTION);

              navigate("/success");
            } catch {
              setShowError(true);
            }
          }}
          validate={async (ihap) => {
            const errors: any = [];
            confirmCosts(ihap, errors);
            return { customErrors: errors };
          }}
          subscription={{ invalid: true, submitting: true, errors: true }}
          render={({ handleSubmit, invalid, submitting, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Stack gap={2}>
                  <CheckboxFormField
                    name="beneficiary"
                    mustBeTrue
                    label="Please confirm you are an Inuvialuit Beneficiary enrolled under the Inuvialuit Final Agreement"
                  />
                  <BeneficiaryInfo />
                  <Divider />
                  <RegistrantInfo />
                  <Divider />
                  <Household namespace="household" />
                  <Divider />
                  <HouseholdEquipment />
                  <Divider />
                  <Box mb={0.5}>
                    <Typography variant="h6">
                      Request for Assistance ~ Please provide a quote from
                      retailer, include freight costs
                    </Typography>
                  </Box>
                  <RequestedEquipment type="major" />
                  <Divider />
                  <RequestedEquipment type="minor" />
                  <Divider />
                  <RequestedEquipmentSum />
                  <Grid item xs={12} sm={12} md={true}>
                    <TextFormField
                      name="comments"
                      label="Reasons for need for assistance."
                      multiline={true}
                      icon={<MuiComment />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Please Attach any additional documents related to this
                      request
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FileFormField
                      multiFile={true}
                      name="additionalDocuments"
                    />
                  </Grid>
                  <Box ml={5}>
                    <Button
                      type="submit"
                      disabled={invalid}
                      variant="contained"
                      size="large"
                    >
                      Submit
                    </Button>

                    <Stack spacing={"5px"}>
                      {errors?.customErrors?.map((error: string) => (
                        <>
                          <hr />
                          <Alert severity="error">{error}</Alert>
                        </>
                      ))}
                    </Stack>
                  </Box>
                </Stack>
              </form>
            );
          }}
        ></Form>
        <ErrorNotification
          open={showError}
          onClose={() => setShowError(false)}
          message={errorMessage}
        />
      </Box>
    );
  } else {
    return (
      <Typography fontSize={24} textAlign={"center"}>
        The IHAP form is not yet available.
      </Typography>
    );
  }
};
