import { Box, Divider, Grid, useTheme } from "@mui/material";
import React from "react";
import { TextFormField } from "../../common/components/FormFields";
import { MuiPerson } from "../../common/icons";

export interface AgencyInfoFieldsProps {}

export const AgencyInfoFields: React.FC<
  AgencyInfoFieldsProps
> = ({}: AgencyInfoFieldsProps) => {
  const theme = useTheme();

  return (
    <Box>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="workerName"
            label="Name of Social Worker or Case Worker"
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField name="agency" label="Name of Agency" />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="agencyPhoneNumber"
            label="Phone Number and Email Address"
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField name="fileStatus" label="Child/Youth File Status" />
        </Grid>
      </Grid>
    </Box>
  );
};
