import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { theme } from "./themes";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AuthenticatedProvider } from "./common/contexts/AuthenticatedProvider";

export interface IrcFormsProps {}

export const IrcForms: React.FC<IrcFormsProps> = ({}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <AuthenticatedProvider>
           <RouterProvider router={router} />
        </AuthenticatedProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};
