import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";
import { FormFieldWrapper, FormFieldWrapperProps } from "./FormFieldWrapper";

export interface TextFormFieldProps
  extends Omit<FormFieldWrapperProps, "children"> {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  multiline?: boolean;
  size?: TextFieldProps["size"];
  forceError?: boolean;
  disabled?: boolean;
  /**@deprecated use form initial values instead */
  value?: string;
  helperText?: string;
}

export const TextFormField: React.FC<TextFormFieldProps> = ({
  name,
  icon,
  noIcon,
  forceError,
  value,
  disabled,
  helperText,
  ...textFieldProps
}) => {
  return (
    <FormFieldWrapper icon={icon} noIcon={noIcon}>
      <Field
        name={name}
        validate={(v) =>
          textFieldProps.required && v == null
            ? "This field is required"
            : undefined
        }
        render={({ input, meta }) => (
          <TextField
            {...textFieldProps}
            fullWidth
            disabled={disabled}
            minRows={textFieldProps.multiline ? 3 : undefined}
            maxRows={textFieldProps.multiline ? 5 : undefined}
            error={(meta.touched && meta.error) || forceError}
            helperText={
              (!forceError && meta.touched && meta.error) || helperText
            }
            inputProps={input}
            defaultValue={value ? value : undefined}
          />
        )}
      />
    </FormFieldWrapper>
  );
};
