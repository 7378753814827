import React, { useState } from "react";
import { Form } from "react-final-form";
import { Box, Grid, Typography } from "@mui/material";
import { FamilyMemberFields } from "./FamilyMemberFields";
import { FamilyMemberEditor } from "./FamilyMemberEditor";
import arrayMutators from "final-form-arrays";
import { MandatoryInformationCollection } from "./MandatoryInformationCollection";
import { SubmitButton } from "../common/components/SubmitButton";
import {
  AnniversaryFormResult,
  submitAnniversaryForm,
} from "../common/api/anniversaryFormApi";
import { useRecaptchaReady } from "../common/hooks/useRecaptchaReady";
import { useNavigate } from "react-router-dom";
import { ErrorNotification } from "../common/components/ErrorNotification";
import { OptionalInformationCollection } from "./OptionalInformationCollection";
import anniversaryBackground from "./40thAnniversary.svg";
import { AnniversaryFormHeader } from "./AnniversaryFormHeader";

const RECAPTCHA_ACTION = "SubmitAnniversaryForm";

const initialValues: Partial<AnniversaryFormResult> = {
  familyMembers: [],
};

export interface AnniversaryFormProps {}

export function AnniversaryForm({}: AnniversaryFormProps) {
  const recaptchaReady = useRecaptchaReady();
  const navigate = useNavigate();

  const [showError, setShowError] = useState(false);

  const submit = async (submission: AnniversaryFormResult) => {
    try {
      const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
      const token = await grecaptcha.enterprise.execute(clientKey, {
        action: RECAPTCHA_ACTION,
      });

      await submitAnniversaryForm(submission, token, RECAPTCHA_ACTION);

      navigate("/success");
    } catch {
      setShowError(true);
    }
  };

  return (
    <Typography fontSize={24} textAlign={"center"}>
      Thank you for your interest in the 40th Anniversary promotional items to celebrate the signing of the Inuvialuit Final Agreement. The deadline for submissions has passed, and the form is now closed.
    </Typography>
  );

  // Eligibility period closed May 5th 2024.
  // return (
  //   <Box position="relative">
  //     <Box position="relative" zIndex={1}>
  //       <AnniversaryFormHeader />
  //       <Form<AnniversaryFormResult>
  //         onSubmit={submit}
  //         initialValues={initialValues}
  //         mutators={{ ...arrayMutators }}
  //         subscription={{ submitting: true }}
  //         render={({ handleSubmit, submitting }) => (
  //           <form onSubmit={handleSubmit}>
  //             <Grid container spacing={2}>
  //               <FamilyMemberFields namespace="applicant" adultOnly />
  //             </Grid>
  //             <OptionalInformationCollection />
  //             <Typography variant="h2" px={5} py={2} fontSize={30}>
  //               Family Members
  //             </Typography>
  //             <FamilyMemberEditor />
  //             <Box my={3}>
  //               <MandatoryInformationCollection />
  //             </Box>
  //             <SubmitButton type="submit" isSubmitting={submitting} />
  //           </form>
  //         )}
  //       />
  //     </Box>
  //
  //     <Box
  //       position="absolute"
  //       top={0}
  //       left={50}
  //       right={0}
  //       textAlign="center"
  //       sx={{ opacity: 0.15 }}
  //     >
  //       <img
  //         src={anniversaryBackground}
  //         alt="Background image"
  //         width="100%"
  //         style={{ maxWidth: 900 }}
  //       />
  //     </Box>
  //     <ErrorNotification
  //       open={showError}
  //       onClose={() => setShowError(false)}
  //       message="An error occurred while submitting the form"
  //     />
  //   </Box>
  // );
}
