import React from "react";
import { useFormState } from "react-final-form";
import { ProjectJewelFormResult } from "../projectJewel";
import { Box, Typography } from "@mui/material";
import {
  CheckboxFormField,
  RadioFormField,
} from "../../common/components/FormFields";

export interface JewelConsentProps {}

export function ProjectJewelConsent({}: JewelConsentProps) {
  const { values } = useFormState<ProjectJewelFormResult>();

  return (
    <Box>
      <Typography variant="h5" paragraph>
        PARTICIPATION, ASSUMPTION OF RISK, RELEASE OF LIABILITY AND INDEMNITY
        AGREEMENT
      </Typography>
      <Typography fontStyle="italic" fontWeight="bold" paragraph>
        Please read the following agreement carefully, since you will be waiving
        certain legal rights.
      </Typography>
      <Typography fontWeight="bold" paragraph>
        To: Inuvialuit Regional Corporation (IRC) and its directors, officers,
        employees, agents, guides, volunteers, independent contractors,
        representatives, successors and assigns.
      </Typography>
      <Typography paragraph>
        <b>
          I, {values.firstName ?? ""} {values.lastName ?? ""}
        </b>
        , have applied to participate in the IRC On-the-Land Program, Project
        Jewel (“Program”). I understand that this Agreement includes all
        activities, events or services provided, organized, conducted or
        authorized by IRC in relation to the Workshop.
      </Typography>
      <Typography paragraph>
        <b>ASSUMPTION OF RISK:</b> I am aware that my participation in the
        Program is voluntary and may involve risks, dangers and hazards
        associated with a multi-day excursion on the land. I freely accept and
        fully assume all such risks, dangers and hazards, including the failure
        on the part of the IRC to safeguard or protect me therefrom, and the
        possibility of personal injury, death, property damage and loss.
      </Typography>
      <Typography paragraph>
        <b>RELEASE OF LIABILITY, WAIVER OF CLAIMS AND INDEMNITY:</b> I agree TO
        WAIVE ANY AND ALL CLAIMS that I have or may in the future have against
        the IRC, TO HOLD HARMLESS AND INDEMNIFY the IRC from any liability and
        to RELEASE the IRC from any and all liability for any loss, damage,
        expense, injury including death that I may suffer, or that my next of
        kin may suffer, resulting from my participation in any part of the due
        to any cause whatsoever in relation to the Program.
      </Typography>
      <Typography paragraph>
        <b>NO TRAVEL OR HEALTH INSURANCE PROVIDED:</b> I understand that the IRC
        has not purchased any health, travel or other insurance on my behalf.
      </Typography>
      <Typography paragraph>
        <b>ZERO TOLERANCE:</b> I understand that the Program is a Zero Tolerance
        program and if I bring or use alcohol or drugs (other than cigarettes or
        medication prescribed to me by a medical doctor) I will be immediately
        removed from the program.
      </Typography>
      <Typography paragraph>
        <b>RESPECT:</b> I will demonstrate respect to facilitators,
        participants, the environment, cabins and all activities forming part of
        the Program. I will follow the direction of the facilitators and
        understand that if a facilitator perceives a risk to any program
        participant or other facilitator, he or she is authorized to remove me
        from the program. I will not use any machinery located at either cabin
        without permission and supervision of the owner.
      </Typography>
      <Typography paragraph>
        <b>RETURN TO TOWN:</b> I understand that during the program we will not
        be traveling to town except in the event of emergency or to replenish
        necessary supplies.
      </Typography>
      <Box pb={2}>
        <CheckboxFormField
          name="iConfirm"
          mustBeTrue
          label="I confirm that I have read and understand this Agreement, that I am waiving certain legal rights on behalf of myself,
        and that by checking this box I hereby agree to the terms and conditions and accept all risks and liabilities as noted above. *"
        />
      </Box>
      <Typography paragraph fontWeight="bold">
        Please be advised that photos and videos may be taken during Project
        Jewel events. These may be used by Inuvialuit Regional Corporation for
        reports, promotional purposes, and may be shared online through the IRC
        website and accompanying social media platforms.
      </Typography>
      <RadioFormField
        name="consent"
        options={["Yes", "No"]}
        required
        label="Do you consent for Inuvialuit Regional Corporation to use photos and videos in which you may appear for these purposes?"
      />
    </Box>
  );
}
