import { IHAPFormSubmission } from "./IHAPForm";
import { RequestedEquipment } from "./RequestedHouseholdEquipment";

export function confirmCosts(ihap: IHAPFormSubmission, errors: any) {
  //check major equipment requests
  if (ihap.majorRequestedEquipment && ihap.majorRequestedEquipment.length > 0) {
    var sum = 0;
    ihap.majorRequestedEquipment.map(function (
      majorRequest: RequestedEquipment,
      index: number
    ) {
      if (
        parseFloat(majorRequest.requestedContribution ?? "") >
        parseFloat(majorRequest.cost ?? "") * 0.75
      ) {
        errors.push(
          "the requested contribution for " +
            majorRequest.description +
            " of " +
            majorRequest.requestedContribution +
            " is more than 75% of the cost of " +
            majorRequest.cost
        );
      }
      sum += parseFloat(majorRequest.requestedContribution ?? "");
    });
    if (sum > 7500) {
      errors.push(
        "the total requested major assistance of $" +
          sum +
          " is too high, the maximim is $7500"
      );
    }
  }

  //check minor
  if (ihap.minorRequestedEquipment && ihap.minorRequestedEquipment.length > 0) {
    sum = 0;
    ihap.minorRequestedEquipment.map(function (
      minorRequest: RequestedEquipment,
      index: number
    ) {
      if (
        parseFloat(minorRequest.requestedContribution ?? "") >
        parseFloat(minorRequest.cost ?? "") * 0.75
      ) {
        errors.push(
          "the requested contribution for " +
            minorRequest.description +
            " of " +
            minorRequest.requestedContribution +
            " is more than 75% of the cost of " +
            minorRequest.cost
        );
      }
      sum += parseFloat(minorRequest.requestedContribution ?? "");
    });
    if (sum > 2500) {
      errors.push(
        "the total requested minor assistance of $" +
          sum +
          " is too high, the maximim is $2500"
      );
    }
  }
}
