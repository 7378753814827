import React, { useState } from "react";
import { Form } from "react-final-form";
import { Box, Stack, Typography } from "@mui/material";
import { SubmitButton } from "../common/components/SubmitButton";
import { useRecaptchaReady } from "../common/hooks/useRecaptchaReady";
import arrayMutators from "final-form-arrays";
import { ChildrenFields, DEFAULT_CHILD_FIELDS } from "./ChildrenFields";
import { CfiHeader } from "./CfiHeader";
import { ParentFields } from "./ParentFields";
import { CfiChild, CfiFormResult } from "./cfiTypes";
import {
  CheckboxFormField,
  TextFormField,
} from "../common/components/FormFields";
import { DEFAULT_ITEM_VALUES, ItemFieldsList } from "./ItemFieldsList";
import { LettersOfSupport } from "./LettersOfSupport";
import { submitCfiApplication } from "../common/api/cfiApplicationApi";
import { ErrorNotification } from "../common/components/ErrorNotification";
import { useNavigate } from "react-router-dom";

const RECAPTCHA_ACTION = "SubmitCfiApplication";

//Declare this outside so that it's the same object being passed to the
// initial values each time instead of recreating it on each render
const initialValues: Partial<CfiFormResult> = {
  children: [{ ...(DEFAULT_CHILD_FIELDS as CfiChild) }],
  items: [{ ...DEFAULT_ITEM_VALUES }],
  parents: {
    names: "",
    contactNumber: "",
    nihb: "",
    address: { country: "Canada" },
    email: "",
  },
};

export interface CfiFormProps {}

export function CfiForm({}: CfiFormProps) {
  const recaptchaReady = useRecaptchaReady();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const submit = async (result: CfiFormResult) => {
    try {
      const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
      const token = await grecaptcha.enterprise.execute(clientKey, {
        action: RECAPTCHA_ACTION,
      });

      await submitCfiApplication(result, token, RECAPTCHA_ACTION);

      navigate("/success");
    } catch {
      setShowError(true);
    }
  };

  return (
    <>
      <Form<CfiFormResult>
        onSubmit={submit}
        subscription={{ submitting: true }}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Stack gap={2}>
              <CfiHeader />
              <ChildrenFields />
              <ParentFields />
              <Box>
                <Box pb={1}>
                  <Typography variant="h5">History & Unique Needs</Typography>
                </Box>
                <TextFormField
                  name="uniqueNeeds"
                  label="A summary of the child or children's history and unique needs"
                  multiline
                />
              </Box>

              <ItemFieldsList />
              <LettersOfSupport />
              <CheckboxFormField
                name="authorizeConsent"
                mustBeTrue
                label="By checking this box, I am confirming that the above information is correct.
                  I give Inuvialuit Regional Corporation permission to verify the information if
                  needed. I give Inuvialuit Regional Corporation consent to communicate with Inuit
                  CFI on my behalf related to this application."
              />
              <Box ml={5} mt={3}>
                <SubmitButton
                  type="submit"
                  disabled={!recaptchaReady}
                  isSubmitting={submitting}
                />
              </Box>
            </Stack>
          </form>
        )}
      />
      <ErrorNotification open={showError} onClose={() => setShowError(false)} />
    </>
  );
}
