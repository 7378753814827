import { OtlFormSubmission } from "../../OtlForm/OtlFormSubmission";
import { sendGet, sendPost } from "../networking/api";
import { apiResponse } from "./Interfaces/response";

export interface OtlSettings {
  enabled: boolean;
  startDate: string;
  endDate: string;
  period: string;
  voucherDate: string;
}

export async function getOtlSettings() {
  return await sendGet<OtlSettings>("/api/otl/settings");
}

export async function submitOTL(otl: OtlFormSubmission): Promise<apiResponse> {
  return await sendPost("/api/otl/add", otl);
}
