import React from "react";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircleIcon from "@mui/icons-material/Circle";

export interface CfiWinterClothingHeaderProps {}

export function CfiWinterClothingHeader({}: CfiWinterClothingHeaderProps) {
  return (
    <Box>
      <Typography variant="h4" paragraph>
        Inuit Child First Initiative Winter Clothing Support Application
      </Typography>
      <Typography paragraph>
      Inuit Child First Initiative (ICFI), for children 0-18 yrs of age, ensures all Inuit children have access to the essential 
      government-funded health, social and educational products, services and supports. The
        Inuit Child First Initiative is designed to meet the unmet needs of
        children 0 - 18 years old.
      </Typography>
      <Typography variant="h5">Eligibility Checklist</Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <CircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Must be 0-18 years old " />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Must be main caregiver of child(ren)" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Child(ren) must be eligible to register under the Inuvialuit Final Agreement" />
        </ListItem>
      </List>
      <Typography variant="h5">Declaration</Typography>
      <Typography paragraph>
        IRC is not responsible for wrong sizes, colours or styles. Exchanges of
        merchandise is the responsibility of the applicant.{" "}
        <b>Please be advised that purchased items are not to be sold.</b>
      </Typography>
    </Box>
  );
}
