import { InquirySubmission } from "../../InquiryForm/InquiryForm";
import { sendPost } from "../networking/api";
import { apiResponse } from "./Interfaces/response";

export async function submitInquiry(
  inquiry: InquirySubmission,
  recaptchaToken: string,
  recaptchaAction: string
): Promise<apiResponse> {
  return await sendPost("/api/inquiry/add", {
    inquiry,
    recaptchaToken,
    recaptchaAction,
  });
}
