import { Typography, Grid, Box } from "@mui/material";
import React from "react";
import { useFormState } from "react-final-form";
import { ListOption } from "../../common/api/Interfaces/listOption";
import {
  SelectFormField,
  SelectFormFieldOption,
  TextFormField,
  CheckboxFormField,
} from "../../common/components/FormFields";
import { DeepPartial } from "../../common/types";
import { RegistrationSubmission } from "../RegistrationForm";

export interface ChildPlaceOfBirthProps {
  communities?: ListOption[];
  isLoading?: boolean;
}

export const ChildPlaceOfBirth: React.FC<ChildPlaceOfBirthProps> = ({
  communities,
  isLoading,
}) => {
  const { values } = useFormState<DeepPartial<RegistrationSubmission>>();

  return (
    <Box>
      <Typography variant="h6">Place of Birth</Typography>
      <Grid container alignItems="center" mt={0} spacing={1}>
        <Grid item xs={true}>
          <SelectFormField
            name="birthCommunity"
            label="Community"
            required
            isLoading={isLoading}
            options={communities
              ?.map(
                (d): SelectFormFieldOption => ({ value: d.id, text: d.name })
              )
              .concat({ value: "other", text: "Other" })}
          />
        </Grid>
        {values?.birthCommunity === "other" && (
          <>
            <Grid item xs={true}>
              <TextFormField
                name="customCommunity"
                label="Custom Community"
                noIcon
              />
            </Grid>
            <Grid item xs={true}>
              <CheckboxFormField
                name="outsideISRForMedicalReasons"
                label="Outside ISR for medical reasons"
                noIcon
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
