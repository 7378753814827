import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CheckboxFormField } from "../common/components/FormFields";
import { useState } from "react";

export interface CdcLiabilityFieldsProps {}

export function CdcLiabilityFields({}: CdcLiabilityFieldsProps) {
  const namespace = "liabilities";

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">
          Release of Liability, Waiver Of Claims and Indemnity Agreement
        </Typography>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography>
            In consideration of IRC approving the application I have submitted
            (which I have agreed to submit voluntarily), for my child(ren), or
            persons I am responsible for to participate in the Inuvialuit Early
            Childhood Program, and for good and valuable consideration, the
            receipt of which is hereby acknowledged, I agree to the following:
          </Typography>
          <ol>
            <Typography component="li">
              <b>TO WAIVE ANY AND ALL CLAIMS</b> that I or persons I am
              responsible for have or may in the future have against IRC{" "}
              <b>AND TO RELEASE IRC</b> from any and all liability for any loss,
              damage, expense or injury, including death, that I or any persons
              I am responsible for may suffer or that my next of kin may suffer
              as a result of participating in the Inuvialuit Early Childhood
              Program, DUE TO ANY CAUSE WHATSOEVER, <b>INCLUDING NEGLIGENCE</b>,
              BREACH OF CONTRACT, OR BREACH OF ANY STATUTORY OR OTHER DUTY OF
              CARE ON THE PART OF IRC. <br />
              <br />
              <b>
                I UNDERSTAND THAT NEGLIGENCE INCLUDES FAILURE ON THE PART OF IRC
                TO TAKE REASONABLE STEPS TO SAFEGUARD OR PROTECT ME AND THE
                PERSONS I AM RESPONSIBLE FOR FROM ANY AND ALL RISKS, DANGERS AND
                HAZARDS WHICH MAY ARISE DURING THE INUVIALUIT EARLY CHILDHOOD
                PROGRAM.
              </b>
              <br />
              <br />
            </Typography>
            <Typography component="li">
              TO HOLD HARMLESS AND INDEMNIFY IRC from any and all liability,
              <b>INCLUDING LIABILITY FOR NEGLIGENCE</b>, for any property damage
              or personal injury to any third party resulting from participating
              in the Inuvialuit Early Childhood Program;
              <br />
              <br />
            </Typography>
            <Typography component="li">
              This Release Agreement shall be effective and binding upon my
              heirs, next of kin, executors, administrators, assigns and
              representatives in the event of my death or incapacity;
              <br />
              <br />
            </Typography>
            <Typography component="li">
              This Release Agreement and any rights, duties and obligations as
              between the parties to this Release Agreement shall be governed by
              and interpreted solely in accordance with the laws of the
              Northwest Territories and no other jurisdiction and no litigation
              involving the parties to this Release Agreement shall be brought
              except within the Northwest Territories and shall be within the
              exclusive jurisdiction of the Supreme Court of the Northwest
              Territories; and In entering into this Release Agreement, I am not
              relying on any oral or written representations or statements made
              by IRC with respect to the safety of the Inuvialuit Early
              Childhood Program or the interpretation of this Release Agreement
              other than what is set forth in this Release Agreement.
              <br />
              <br />
            </Typography>
          </ol>
        </Box>
      </Modal>
      <Grid container spacing={0.5} flex={1}>
        <Grid item xs={12}>
          <Box ml={5}>
            <Button onClick={handleOpen}>RELEASE AGREEMENT</Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CheckboxFormField
            name={`${namespace}.agreesToLiabilityTerms`}
            mustBeTrue
            label={`I HAVE READ THIS RELEASE AGREEMENT AND AGREE TO BE BOUND BY ITS TERMS.`}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
