import {
  Box,
  FormHelperText,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  CheckboxFormField,
  DateFormField,
  FileFormField,
  TextFormField,
} from "../common/components/FormFields";
import { CdcAllergyFields } from "./CdcAllergyFields";
import { CdcFoodRequirementFields } from "./CdcFoodRequirementsFields";
import { useFormState } from "react-final-form";
import { DeepPartial } from "../common/types";
import { CdcFormResult } from "./CdcTypes";

export interface CdcHealthCareFieldProps {}

export function CdcHealthCareFields({}: CdcHealthCareFieldProps) {
  const namespace = "healthCareInfo";

  const { values } = useFormState<DeepPartial<CdcFormResult>>();

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Health Care Information</Typography>
      </Box>
      <Grid container spacing={0.5} flex={1}>
        <Grid item xs={12}>
          <Box ml={5}>
            <Typography>Health Care Provider</Typography>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name={`${namespace}.provider`}
            label="Name of Health Care Provider/Family Doctor"
            required
          />
        </Grid>
        <Grid container spacing={0.5} pt={1}>
          <Grid item sm={6} xs={12}>
            <FileFormField
              name={`${namespace}.immunizationRecord`}
              label={"Child's Annual Immunization Record"}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxFormField
              name={`${namespace}.hasMedicalRecords`}
              label="Does your child have any record of a medical, physical, developmental or emotional condition relevant to his or her care?"
            />
          </Grid>
          {values.healthCareInfo?.hasMedicalRecords && (
            <Grid item sm={6} xs={12}>
              <FileFormField
                name={`${namespace}.medicalRecords`}
                multiFile
                required
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box ml={5}>
              <Typography>
                <Box sx={{ fontWeight: "bold" }}>
                  Consent Form For Emergency Care and Transportation
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box ml={5}>
              <Typography variant="caption">
                {`If, at any time, due to such circumstances as an injury or
                sudden illness, medical treatment is necessary, I give
                permission to the porgram staff to take whatever emergency
                measures they deem necessary for the protection of 
                ${values.applicant?.firstName} ${values.applicant?.lastName}(my child) while in their care. 
                `}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CheckboxFormField
              name={`${namespace}.agreesToEmergencyCare`}
              mustBeTrue
              label="I understand that this may involve contacting a doctor,
                interpreting and carrying out his or her instructions, and
                transporting my child to a hospital or Health Centre, including
                the possuble use of an ambulance. I understand this may done
                prior to contacting me, and that any expense incurred for such
                treatement, including ambulance fees, is my responsibility."
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CdcAllergyFields />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CdcFoodRequirementFields />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
