import { Box, Divider, Grid, useTheme } from "@mui/material";
import React from "react";
import { TextFormField } from "../../common/components/FormFields";
import { MuiPerson, MuiPhone } from "../../common/icons";

export interface LegalInfoFieldsProps {}

function buildPersonRow(baseName: string, label: string) {
  return (
    <>
      <Grid item sm={6} xs={12}>
        <TextFormField
          name={`${baseName}.name`}
          label={label}
          icon={<MuiPerson />}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextFormField
          name={`${baseName}.contactInfo`}
          label="Contact Information"
          icon={<MuiPhone />}
        />
      </Grid>
    </>
  );
}

export const LegalInfoFields: React.FC<
  LegalInfoFieldsProps
> = ({}: LegalInfoFieldsProps) => {
  const theme = useTheme();

  return (
    <Box>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={1}>
        {buildPersonRow("parentLawyers[0]", "Parent's Lawyer")}
        {buildPersonRow("parentLawyers[1]", "Parent's Lawyer")}
        {buildPersonRow("childLawyer", "Child's Lawyer")}
        {buildPersonRow("agencyLawyer", "Agency's Lawyer")}
        <Grid item sm={12} xs={12}>
          <TextFormField
            name="otherFamily"
            label="Other Extended Family Member(s) Involved (Name, Relation, Phone Number, Home Address)"
            multiline
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextFormField
            name="otherSupports"
            label="Other Formal/Informal Supports Involved (Name, Relation Phone Number, Home Address)"
            multiline
          />
        </Grid>
      </Grid>
    </Box>
  );
};
