import { sendFormDataPost } from "../networking/api";
import { apiResponse } from "./Interfaces/response";
import { CfiFormResult } from "../../CfiForm/cfiTypes";
import { serialize } from "object-to-formdata";

export async function submitCfiApplication(
  application: CfiFormResult,
  recaptchaToken: string,
  recaptchaAction: string
): Promise<apiResponse> {
  const formData = serialize(
    { application, recaptchaToken, recaptchaAction },
    { indices: true }
  );

  return await sendFormDataPost("/api/cfi-application", formData);
}
