import React from "react";
import { Stack, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/ErrorRounded";

export interface ErrorMessageProps {
  icon?: React.ReactNode;
  message?: string;
}

export function ErrorMessage({ icon, message }: ErrorMessageProps) {
  return (
    <Stack gap={2}>
      <Typography
        fontSize={100}
        lineHeight={0.5}
        textAlign="center"
        color="primary.light"
      >
        {icon ?? <ErrorIcon fontSize="inherit" />}
      </Typography>
      <Typography textAlign="center" color="primary" fontStyle="italic">
        {message ?? "An unknown error has occurred"}
      </Typography>
    </Stack>
  );
}
