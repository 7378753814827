import { Box, FormHelperText, Typography } from "@mui/material";
import React, { useState } from "react";
import { useFormState } from "react-final-form";
import { RadioFormField } from "../../common/components/FormFields";
import { DeepPartial } from "../../common/types";
import { InquirySubmission } from "../InquiryForm";
import { ConsentTerms } from "./ConsentTerms";
import { ScrollToBottomDetectionBox } from "./ScrollToBottomDetectionBox";

export interface ConsentFormProps {
  onReadConsent: () => void;
}

export const ConsentForm: React.FC<ConsentFormProps> = ({ onReadConsent }) => {
  const { values } = useFormState<DeepPartial<InquirySubmission>>({
    subscription: { values: true },
  });

  return (
    <Box>
      <Typography variant="h4">
        Consent For Collection, Use and Disclosure of Personal Information
      </Typography>
      <ScrollToBottomDetectionBox
        height={500}
        sx={{ overflowY: "scroll" }}
        m={2}
        p={2}
        border="1px solid #ccc"
        borderRight="none"
        onScrolledToBottom={() => onReadConsent()}
      >
        <ConsentTerms />
      </ScrollToBottomDetectionBox>
      <Box mt={2} mb={1}>
        <Typography variant="h6">Your consent</Typography>
      </Box>
      <RadioFormField
        name="releaseConsent"
        label="I have read, understand and consent to provide IRC with my personal 
          information and agree to its collection, use and disclosure as articulated above"
        options={["Yes", "No"]}
        disabled={!values.hasReadConsentForm}
      />
      <Box mt={2}>
        <Typography variant="h6" paragraph>
          Consent to Authorize the Inuvialuit Regional Corporation to Request
          Information on behalf of family members searching for their missing
          family member pursuant to the Nanilavut Initiative.
        </Typography>
        <Typography>
          As next of kin to{" "}
          <b>
            {values.individualLastName} {values.individualFirstName}, I
            HEREBY AUTHORIZE AND DIRECT
          </b>{" "}
          you to provide to <b>INUVIALUIT REGIONAL CORPORATION</b> and its
          agents, including, but not limited to, Beverly Lennie, any and all
          medical information and documentation in your possession, including
          but not limited to all documents, clinical notes, medical records,
          reports, test results, diagnostic tests, information, opinions, notes,
          memos, and correspondence, as well as to answer questions and provide
          any other information requested, and for so doing, let this be your
          good and sufficient authority and direction
        </Typography>
        <RadioFormField name="authorizeConsent" options={["Yes", "No"]} />
      </Box>
    </Box>
  );
};
