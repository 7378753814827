import { Form } from "react-final-form";
import { CfiFormResult } from "../CfiForm/cfiTypes";
import { useRecaptchaReady } from "../common/hooks/useRecaptchaReady";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  MaligaksatFamilyInfoSubmission,
  submitMaligaksatFamilyInfo,
} from "../common/api/maligaksatFamilyInfoApi";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { MaligaksatFamilyInfoHeader } from "./MaligaksatFamilyInfoHeader";
import { RadioFormField } from "../common/components/FormFields";
import { ChildFields } from "./Fields/ChildFields";
import { ParentFields } from "./Fields/ParentFields";
import { LegalGuardianFields } from "./Fields/LegalGuardianFields";
import { CareGiverFields } from "./Fields/CareGiverFields";
import { LegalInfoFields } from "./Fields/LegalInfoFields";
import { AgencyInfoFields } from "./Fields/AgencyInfoFields";
import { FileInfoFields } from "./Fields/FileInfoFields";
import { ErrorNotification } from "../common/components/ErrorNotification";
import { ChildrenFields } from "./Fields/ChildrenFields";
import arrayMutators from "final-form-arrays";
import { SubmitButton } from "../common/components/SubmitButton";

const RECAPTCHA_ACTION = "SubmitFamilyInfo";

export interface MaligaksatFamilyInfoFormProps {}

export function MaligaksatFamilyInfoForm({}: MaligaksatFamilyInfoFormProps) {
  const recaptchaReady = useRecaptchaReady();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  return (
    <>
      <Form<MaligaksatFamilyInfoSubmission>
        onSubmit={async (maligaksatFamilyInfo) => {
          try {
            const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
            const token = await grecaptcha.enterprise.execute(clientKey, {
              action: RECAPTCHA_ACTION,
            });
            await submitMaligaksatFamilyInfo(
              maligaksatFamilyInfo,
              token,
              RECAPTCHA_ACTION
            );
            navigate("/success");
          } catch {
            setShowError(true);
          }
        }}
        subscription={{ invalid: true, submitting: true }}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <MaligaksatFamilyInfoHeader />
            </Box>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={6} sm={12} md={true}>
                <RadioFormField
                  name="sharedWithFamily"
                  label="Have you shared the Maligaksat Services One Pager with the Family?"
                  options={["Yes", "No"]}
                  required
                />
              </Grid>
            </Grid>

            <Typography variant="h5" sx={{ mt: 3 }}>
              CHILD/YOUTH INFORMATION
            </Typography>
            <ChildrenFields />

            <Typography variant="h5" sx={{ mt: 3 }}>
              PARENT INFORMATION
            </Typography>
            <ParentFields index={0} />
            <ParentFields index={1} />

            <Typography variant="h5" sx={{ mt: 3 }}>
              LEGAL GUARDIAN(S) INFORMATION
            </Typography>
            <LegalGuardianFields />

            <Typography variant="h5" sx={{ mt: 3 }}>
              CAREGIVER(S) INFORMATION
            </Typography>
            <CareGiverFields />

            <Typography variant="h5" sx={{ mt: 3 }}>
              LEGAL INFORMATION
            </Typography>
            <LegalInfoFields />

            <Typography variant="h5" sx={{ mt: 3 }}>
              AGENCY INFORMATION
            </Typography>
            <AgencyInfoFields />

            <Typography variant="h5" sx={{ mt: 3 }}>
              FILE INFORMATION
            </Typography>
            <FileInfoFields />

            <Box ml={5} mt={3}>
              <SubmitButton
                type="submit"
                disabled={invalid || !recaptchaReady}
                isSubmitting={submitting}
              />
            </Box>
          </form>
        )}
      />
      <ErrorNotification open={showError} onClose={() => setShowError(false)} />
    </>
  );
}
