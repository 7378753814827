import { useNavigate } from "react-router-dom";
import { useRecaptchaReady } from "../common/hooks/useRecaptchaReady";
import {
  CfiWinterClothingChild,
  CfiWinterClothingFormResult,
} from "./CfiWinterClothingTypes";
import {
  DEFAULT_CHILD_FIELDS,
  WinterClothingChildrenFields,
} from "./WinterClothingChildrenFields";
import { useState } from "react";
import arrayMutators from "final-form-arrays";
import { ErrorNotification } from "../common/components/ErrorNotification";
import { submitCfiWinterClothingApplication } from "../common/api/cfiWinterClothingApplicationApi";
import { Form } from "react-final-form";
import { Box, Stack, Typography } from "@mui/material";
import { CfiWinterClothingHeader } from "./CfiWinterClothingHeader";
import { WinterClothingParentFields } from "./WinterClothingParentFields";
import {
  CheckboxFormField,
  TextFormField,
} from "../common/components/FormFields";
import { SubmitButton } from "../common/components/SubmitButton";
import { WinterClothingPurchaseFields } from "./WinterClothingPurchaseFields";

const RECAPTCHA_ACTION = "SubmitCfiWinterClothingApplication";

//Declare this outside so that it's the same object being passed to the
// initial values each time instead of recreating it on each render
const initialValues: Partial<CfiWinterClothingFormResult> = {
  children: [{ ...(DEFAULT_CHILD_FIELDS as CfiWinterClothingChild) }],
  parent1: {
    name: "",
    contactNumber: "",
    email: "",
  },
  parent2: {
    name: "",
    contactNumber: "",
    email: "",
  },
  address: {
    line1: "",
    line2: "",
    community: "",
    region: "",
    country: "Canada",
    postalCode: "",
  },
  typeOfSupport: undefined,
  vendor: "",
  purchaseFiles: [],
};

export interface CfiWinterClothingFormProps {}

export function CfiWinterClothingForm({}: CfiWinterClothingFormProps) {
  const recaptchaReady = useRecaptchaReady();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const submit = async (result: CfiWinterClothingFormResult) => {
    try {
      const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
      const token = await grecaptcha.enterprise.execute(clientKey, {
        action: RECAPTCHA_ACTION,
      });

      await submitCfiWinterClothingApplication(result, token, RECAPTCHA_ACTION);

      navigate("/success");
    } catch {
      setShowError(true);
    }
  };

  return (
    <>
      <Form<CfiWinterClothingFormResult>
        onSubmit={submit}
        subscription={{ submitting: true }}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Stack gap={2}>
              <CfiWinterClothingHeader />
              <WinterClothingParentFields />
              <WinterClothingChildrenFields />
              <WinterClothingPurchaseFields />
              <Box>
                <Typography variant="h5">Application Consent</Typography>
              </Box>
              <CheckboxFormField
                name="authorizeConsent"
                mustBeTrue
                label="By checking this box, I consent to IRC using and collecting identifying information for myself and my child(ren) to validate the information provided in this form, and confirm eligibility to participate in this initiative. This information includes Inuvialuit file numbers, N numbers, names, dates of birth, phone numbers, email addresses and mailing addresses. I further consent to and acknowledge that IRC may disclose my personal information and the personal information of my child(ren) internally to its divisions for the sole purpose of assessing this application."
              />
              <CheckboxFormField
                name="confirmNotApproval"
                mustBeTrue
                label="I understand that this form is not an automatic approval and that I will receive communication once a decision has been made. I further understand that providing false or misleading information on this form may lead to my application being rejected."
              />
              <Box ml={5} mt={3}>
                <SubmitButton
                  type="submit"
                  disabled={!recaptchaReady}
                  isSubmitting={submitting}
                />
              </Box>
            </Stack>
          </form>
        )}
      />
      <ErrorNotification open={showError} onClose={() => setShowError(false)} />
    </>
  );
}
