import { serialize } from "object-to-formdata";
import { RegistrationSubmission } from "../../RegistrationForm/RegistrationForm";
import { sendPost, sendPostForm } from "../networking/api";
import { apiResponse } from "./Interfaces/response";

export async function submitRegistration(
  registration: Omit<RegistrationSubmission, "birthCertificate">,
  recaptchaToken: string,
  recaptchaAction: string
): Promise<number> {
  return await sendPost("/api/registration/add", {
    registration,
    recaptchaToken,
    recaptchaAction,
  });
}

export async function attachBirthCertificate(
  file: File,
  id: number
): Promise<apiResponse|null> {
    return sendPostForm(
      "/api/registration/attach-birth-certificate",
      serialize({ file, id }, { indices: true })
    );
}
