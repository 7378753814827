import { FormHelperText, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useFormState } from "react-final-form";
import { FormFieldWrapper, FormFieldWrapperProps } from "./FormFieldWrapper";
import { TextFormField } from "./TextFormField";
import { SelectFormField, SelectFormFieldOption } from "./SelectFormField";

export interface AddressFormFieldsProps
  extends Omit<FormFieldWrapperProps, "children"> {
  name: string;
  label: string;
  required?: boolean;
}

const countries: SelectFormFieldOption[] = [
  { value: "CA", text: "Canada" },
  { value: "US", text: "United States of America" },
  { value: "GB", text: "United Kingdom" },
  { value: "NZ", text: "New Zealand" },
];

const provincesOrTerritories: SelectFormFieldOption[] = [
  { value: "BC", text: "British Columbia" },
  { value: "AB", text: "Alberta" },
  { value: "SK", text: "Saskatchewan" },
  { value: "MB", text: "Manitoba" },
  { value: "ON", text: "Ontario" },
  { value: "QC", text: "Quebec" },
  { value: "NL", text: "Newfoundland and Labrador" },
  { value: "PE", text: "Prince Edward Island" },
  { value: "NS", text: "Nova Scotia" },
  { value: "NB", text: "New Brunswick" },
  { value: "YT", text: "Yukon" },
  { value: "NT", text: "Northwest Territories" },
  { value: "NU", text: "Nunavut" },
  { value: "Other", text: "Other" },
];

// @ts-ignore ts(7031)
const CustomProvinceOrTerritory = ({ val }) => (
  <Grid item xs={6}>
    <TextFormField
      name={`${val}.customProvinceOrTerritory`}
      label="Custom Province/Territory"
      noIcon
      size="small"
    />
  </Grid>
);

/** @deprecated Consider using InternationalAddressFields instead */
export const AddressFormFields: React.FC<AddressFormFieldsProps> = ({
  name,
  label,
  required,
  ...wrapperProps
}) => {
  const { errors, touched } = useFormState();
  const [showCustomProvince, setShowCustomProvince] = React.useState(false);
  const error = errors?.[name];

  return (
    <FormFieldWrapper {...wrapperProps}>
      {label && <Typography color={grey["700"]}>{label}</Typography>}
      <Grid container spacing={0.5} mt={label ? 0 : undefined}>
        <Grid item xs={6}>
          <TextFormField
            name={`${name}.line1`}
            label="Line 1"
            noIcon
            size="small"
            required={required}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            name={`${name}.line2`}
            label="Line 2"
            noIcon
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            name={`${name}.city`}
            label="City"
            noIcon
            size="small"
            required={required}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectFormField
            name={`${name}.provinceOrTerritory`}
            label="Province/Territory"
            noIcon
            size="small"
            options={provincesOrTerritories}
            required={required}
            changeFn={(e: React.ChangeEvent<Element>) => {
              // typings aren't recognizing target
              // @ts-ignore ts(2339)
              if (e.target.value === 14) {
                setShowCustomProvince(true);
              } else {
                setShowCustomProvince(false);
              }
            }}
          />
        </Grid>
        {showCustomProvince ? <CustomProvinceOrTerritory val={name} /> : null}
        <Grid item xs={6}>
          <TextFormField
            name={`${name}.postalCode`}
            label="Postal/ZIP Code"
            noIcon
            size="small"
            required={required}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectFormField
            name={`${name}.country`}
            label="Country"
            noIcon
            options={countries}
            size="small"
            required={required}
          />
        </Grid>
      </Grid>
    </FormFieldWrapper>
  );
};
