import { sendFormDataPost } from "../networking/api";
import { apiResponse } from "./Interfaces/response";
import { serialize } from "object-to-formdata";
import { CfiWinterClothingFormResult } from "../../CfiWinterClothingForm/CfiWinterClothingTypes";

export async function submitCfiWinterClothingApplication(
  application: CfiWinterClothingFormResult,
  recaptchaToken: string,
  recaptchaAction: string
): Promise<apiResponse> {
  const formData = serialize(
    { application, recaptchaToken, recaptchaAction },
    { indices: true }
  );

  return await sendFormDataPost(
    "/api/cfi-winter-clothing-application",
    formData
  );
}
