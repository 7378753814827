import { Box, Typography, Link } from "@mui/material";
import React from "react";

export interface RegistrationFormHeaderProps {}

export const RegistrationFormHeader: React.FC<
  RegistrationFormHeaderProps
> = ({}) => {
  return (
    <Box>
      <Typography variant="h4" paragraph>
        Inuvialuit Child Registration Form
      </Typography>
      <Typography paragraph>
        Registering with the Inuvialuit Regional Corporation before the age of
        18 allows young people to access IRC children's programs and assistance
        like student loans through the Inuvialuit Education Fund. Registration
        also allows the child's status to be verified with Health Canada for the
        purpose of{" "}
        <Link href="https://www.sac-isc.gc.ca/eng/1572537161086/1572537234517">
          Non-Insured Health Benefits
        </Link>{" "}
        and their rights under the Inuit Child First Initiative.
      </Typography>
      <Typography paragraph>
        Eligible children with one or more parent or grandparent who are
        Inuvialuit can register even if they may potentially enrol at age 18 or
        older as the beneficiary of another land claim settlement (Gwich'in,
        Nunavut, etc). You do NOT have to live in the Inuvialuit Settlement
        Region to register.
      </Typography>
    </Box>
  );
};
