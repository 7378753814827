import React from "react";
import { Box } from "@mui/system";
import { Grid, Skeleton, Typography } from "@mui/material";

export interface TravelRequestSkeletonProps {}

export function TravelRequestSkeleton({}: TravelRequestSkeletonProps) {
  return (
    <Box>
      <Typography variant="h5" mb={2}>
        <Skeleton width={200} />
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={12} md={true}>
          <Skeleton height={56} variant="rectangular" />
        </Grid>
        <Grid item xs={12} sm={12} md={true}>
          <Skeleton height={56} variant="rectangular" />
        </Grid>
        <Grid item xs={12} sm={12} md={true}>
          <Skeleton height={56} variant="rectangular" />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={12} md={true}>
          <Skeleton height={56} variant="rectangular" />
        </Grid>
        <Grid item xs={12} sm={12} md={true}>
          <Skeleton height={56} variant="rectangular" />
        </Grid>
        <Grid item xs={12} sm={12} md={true}>
          <Skeleton height={56} variant="rectangular" />
        </Grid>
      </Grid>
      <Skeleton height={102} variant="rectangular" />
      <Box mt={2} ml={10}>
        <Skeleton height={152} variant="rectangular" />
      </Box>
    </Box>
  );
}
