import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { TextFormField } from "../common/components/FormFields";

export interface CdcContactFieldProps {
  namespace: string;
  required?: boolean;
}

export function CdcContactFields({
  namespace,
  required,
}: CdcContactFieldProps) {
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Grid container spacing={0.5} pt={1}>
      <Grid item xs={12}>
        <Box ml={5}>
          <Typography>Contact Info</Typography>
        </Box>
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <TextFormField
          name={`${namespace}.homePhone`}
          label="Home Phone #"
          required={required}
        />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <TextFormField
          name={`${namespace}.workPhone`}
          label="Work Phone #"
          required={required}
          noIcon={sm}
        />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <TextFormField
          name={`${namespace}.cellPhone`}
          label="Cell Phone #"
          required={required}
          noIcon={sm}
        />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <TextFormField
          name={`${namespace}.emailAddress`}
          label="Email Address"
          required={required}
          noIcon={sm}
        />
      </Grid>
    </Grid>
  );
}
