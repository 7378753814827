import React, {useState} from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ProjectJewelHeaderInfo } from "./Sections/ProjectJewelHeaderInfo";
import { Form } from "react-final-form";
import { ProjectJewelFormResult } from "./projectJewel";
import { DateFormField, TextFormField } from "../common/components/FormFields";
import { MuiPerson } from "../common/icons";
import { PersonalInformation } from "./Sections/PersonalInformation";
import { ContactInformation } from "./Sections/ContactInformation";
import { HealthInformation } from "./Sections/HealthInformation";
import { ProjectJewelConsent } from "./Sections/ProjectJewelConsent";
import { useRecaptchaReady } from "../common/hooks/useRecaptchaReady";
import { submitInquiry } from "../common/api";
import { submitJewelHealthDisclosure } from "../common/api/jewelApi";
import { useNavigate } from "react-router-dom";
import { SubmitButton } from "../common/components/SubmitButton";
import {ErrorNotification} from "../common/components/ErrorNotification";

const RECAPTCHA_ACTION = "SubmitJewelForm";

export interface ProjectJewelFormProps {}

export function ProjectJewelForm({}: ProjectJewelFormProps) {
  const recaptchaReady = useRecaptchaReady();
  const navigate = useNavigate();

  const [showError, setShowError] = useState(false);

  const submit = async (submission: ProjectJewelFormResult) => {
    try {
      const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
      const token = await grecaptcha.enterprise.execute(clientKey, {
        action: RECAPTCHA_ACTION,
      });

      await submitJewelHealthDisclosure(submission, token, RECAPTCHA_ACTION);

      navigate("/success");
    } catch {
      setShowError(true);
    }
  };

  return (
    <Box>
      <ProjectJewelHeaderInfo />
      <Form<ProjectJewelFormResult>
        onSubmit={submit}
        subscription={{ values: true, invalid: true, submitting: true }}
        validate={(values) => {
          const errors: any = {};

          if (values.consent !== "Yes") {
            errors.consent =
              "You must consent to the release of your information in order to submit this form.";
          }

          return errors;
        }}
        render={({ handleSubmit, invalid, submitting }) => (
          <>
            <Stack gap={3} divider={<Divider />}>
              <PersonalInformation />
              <ContactInformation />
              <HealthInformation />
              <ProjectJewelConsent />
            </Stack>
            <Box ml={5} mt={3}>
              <SubmitButton
                type="submit"
                disabled={invalid || !recaptchaReady}
                isSubmitting={submitting}
                onClick={handleSubmit}
              />
            </Box>
          </>
        )}
      />

      <ErrorNotification open={showError} onClose={() => setShowError(false)} />
    </Box>
  );
}
