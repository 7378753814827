import type {} from "@mui/x-date-pickers/themeAugmentation";
import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 630, //600 was just a pinch too small
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#0055A5",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          boxShadow: "3px 3px 20px -5px rgba(0, 0, 0, 0.15)",
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: "filled",
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(212, 212, 212, 0.36)",
        },
      },
    },
  },
});

export { theme };
