import React from "react";
import { FormFieldWrapper, FormFieldWrapperProps } from "./FormFieldWrapper";
import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import { Field } from "react-final-form";
import { Simulate } from "react-dom/test-utils";
import input = Simulate.input;

export interface AutocompleteFormFieldProps
  extends Omit<FormFieldWrapperProps, "children"> {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  options: string[];
  size?: TextFieldProps["size"];
  forceError?: boolean;
  disabled?: boolean;
  value?: string;
  helperText?: string;
  getOptionDisabled?: (option: any) => boolean;
}

export function AutocompleteFormField({
  name,
  icon,
  noIcon,
  forceError,
  options,
  value,
  disabled,
  helperText,
  label,
  required,
  getOptionDisabled,
  ...autocompleteProps
}: AutocompleteFormFieldProps) {
  return (
    <FormFieldWrapper icon={icon} noIcon={noIcon}>
      <Field
        name={name}
        validate={(v) =>
          required && v == null ? "This field is required" : undefined
        }
        render={({ input, meta }) => (
          <Autocomplete
            options={options}
            freeSolo={false}
            disabled={disabled}
            {...autocompleteProps}
            {...input}
            onChange={(e, value) => input.onChange(value)}
            getOptionDisabled={getOptionDisabled}
            renderInput={(inputProps) => (
              <TextField
                {...inputProps}
                label={label}
                fullWidth
                required={required}
                error={(meta.touched && meta.error) || forceError}
                helperText={
                  (!forceError && meta.touched && meta.error) || helperText
                }
              />
            )}
          />
        )}
      />
    </FormFieldWrapper>
  );
}
