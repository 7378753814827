import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FieldArray } from "react-final-form-arrays";
import {
  CheckboxFormField,
  TextFormField,
} from "../common/components/FormFields";
import MuiDelete from "@mui/icons-material/Delete";
import { CdcAllergy, CdcFormResult } from "./CdcTypes";
import { MuiAdd } from "../common/icons";
import { useFormState } from "react-final-form";
import { DeepPartial } from "../common/types";

export interface CdcFoodRequirementFieldsProps {}

export const DEFAULT_FOOD_REQUIREMENT_FIELDS: Partial<CdcAllergy> = {
  name: "",
};

export function CdcFoodRequirementFields({}: CdcFoodRequirementFieldsProps) {
  const { values } = useFormState<DeepPartial<CdcFormResult>>();
  return (
    <Box>
      <Grid item xs={12}>
        <CheckboxFormField
          name={`healthCareInfo.hasFoodRequirements`}
          label="Has Food Requirements"
        />
      </Grid>
      {values.healthCareInfo?.hasFoodRequirements && (
        <FieldArray<Partial<CdcAllergy>>
          name={"healthCareInfo.foodRequirementsList"}
          render={({ fields }) => (
            <Stack gap={1}>
              {fields.map((name, index) => (
                <Stack key={index} direction="row" alignItems="center" gap={1}>
                  <Grid container spacing={0.5} flex={1}>
                    <Grid item xs={12}>
                      <TextFormField
                        name={`${name}.name`}
                        label="Food Requirement"
                        required
                      />
                    </Grid>
                  </Grid>
                  <IconButton
                    color="error"
                    onClick={() => fields.remove(index)}
                  >
                    <MuiDelete />
                  </IconButton>
                </Stack>
              ))}
              <Box ml={5}>
                <Button
                  startIcon={<MuiAdd />}
                  variant="outlined"
                  onClick={() =>
                    fields.push({ ...DEFAULT_FOOD_REQUIREMENT_FIELDS })
                  }
                >
                  Add Allergy
                </Button>
              </Box>
            </Stack>
          )}
        />
      )}
    </Box>
  );
}
