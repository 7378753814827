import { Alert, Box, IconButton, Snackbar } from "@mui/material";
import React, { useRef, useState, DragEvent } from "react";
import { MuiAdd, MuiAddCircleOutline, MuiClose, MuiUpload } from "../../icons";
import styles from "./FileDropper.module.css";
import { useWindowDragFileListener } from "./useWindowDragFileListener";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";

export interface FileDropperProps {
  multiFile?: boolean;
  onChange?: (files: File | File[]) => void;
}

export const FileDropper: React.FC<FileDropperProps> = ({
  multiFile,
  onChange,
}) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    maxFiles: multiFile ? undefined : 1,
    onDragEnter: () => setDragOverDropzone(true),
    onDragOver: (e) => {
      e.preventDefault();
      e.stopPropagation();
    },
    onDragLeave: () => setDragOverDropzone(false),
    onDrop: () => {
      setDraggingFile(false);
      setDragOverDropzone(false);
    },
    onDropAccepted: (acceptedFiles, event) => {
      onChange && onChange(multiFile ? acceptedFiles : acceptedFiles[0]);
    },
    onDropRejected: () => {
      setShowMultifileError(true);
    },
  });

  const [draggingFile, setDraggingFile] = useState(false);
  const [dragOverDropzone, setDragOverDropzone] = useState(false);

  const [showMultifileError, setShowMultifileError] = useState(false);

  useWindowDragFileListener(setDraggingFile);

  return (
    <>
      <Box
        {...getRootProps()}
        className={classNames(styles.dropzone, {
          [styles.draggingFile]: draggingFile,
          [styles.dragOver]: dragOverDropzone,
        })}
      >
        <input {...getInputProps()} />
        <Box className={styles.dropHereText}>
          {!draggingFile && acceptedFiles.length === 0 && (
            <Box>
              <MuiAdd /> {`${multiFile ? "Add files" : "Add a file"}`}
            </Box>
          )}
          {draggingFile && (
            <Box>
              <MuiAddCircleOutline /> Drop your file here
            </Box>
          )}
          {acceptedFiles.length > 0 &&
            acceptedFiles.length <= 3 &&
            !draggingFile && (
              <Box>
                <MuiUpload /> {acceptedFiles.map((f) => f.name).join(", ")}
              </Box>
            )}
          {acceptedFiles.length > 3 && !draggingFile && (
            <Box>
              <MuiUpload /> {acceptedFiles.length} Files
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar
        open={showMultifileError}
        autoHideDuration={6000}
        onClose={() => setShowMultifileError(false)}
      >
        <Alert
          severity="error"
          onClose={() => setShowMultifileError(false)}
          sx={{ width: "100%" }}
          elevation={4}
        >
          Only one file may be uploaded
        </Alert>
      </Snackbar>
    </>
  );
};
