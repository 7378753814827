import React from "react";
import { TextFormField } from "../TextFormField";
import { useMediaQuery, useTheme } from "@mui/material";
import { AutocompleteFormField } from "../AutocompleteFormField";
import { provincesAndTerritoris, usStates } from "./places";

export interface RegionSelectorProps {
  namespace: string;
  selectedCountry?: string;
  required?: boolean;
}

export function RegionSelector({
  namespace,
  required,
  selectedCountry,
}: RegionSelectorProps) {
  if (
    selectedCountry === "Canada" ||
    selectedCountry === "United States of America"
  ) {
    return (
      <AutocompleteFormField
        name={`${namespace}.region`}
        required={required}
        options={
          selectedCountry === "Canada" ? provincesAndTerritoris : usStates
        }
        label={selectedCountry === "Canada" ? "Province/Territory" : "State"}
        size="small"
      />
    );
  }

  return (
    <TextFormField
      name={`${namespace}.region`}
      required={required}
      label="Region"
      size="small"
    />
  );
}
