import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  AddressFormFields,
  DateFormField,
  InternationalAddressFields,
  SelectFormField,
  TextFormField,
} from "../common/components/FormFields";
import { MuiPerson } from "../common/icons";
import dayjs from "dayjs";

export interface CdcApplicantFieldProps {}

export function CdcApplicantFields({}: CdcApplicantFieldProps) {
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  const namespace = "applicant";

  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Applicant/Child Information</Typography>
      </Box>
      <Grid container spacing={0.5} flex={1}>
        <Grid item lg={3} sm={6} xs={12}>
          <TextFormField
            name={`${namespace}.lastName`}
            label="Last Name"
            required
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <TextFormField
            name={`${namespace}.firstName`}
            label="First Name"
            required
            noIcon={sm}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <SelectFormField
            name={`${namespace}.boyOrGirl`}
            label="Boy/Girl"
            options={["Boy", "Girl"]}
            required
            noIcon={sm}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <DateFormField
            name={`${namespace}.dateOfBirth`}
            label="Date Of Birth"
            required
            stringOutput
            noIcon={sm}
            minDate={dayjs().subtract(20, "years")}
          />
        </Grid>
        <Grid item xs={12}>
          <AddressFormFields
            name={`${namespace}.address`}
            required
            label="Address"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0.5} pt={1}>
            <Grid item md={6} xs={12}>
              <SelectFormField
                name={`${namespace}.ethnicity`}
                label="Ethnicity"
                options={["Inuvialuit", "Gwichin", "Other"]}
                size="small"
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextFormField
                name={`${namespace}.healthCardNumber`}
                label="Health Care Card #"
                size="small"
                required
                noIcon={md}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
