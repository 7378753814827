import { Box, FormLabel, Grid, Stack } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";
import {
  TextFormField,
  CheckboxFormField,
} from "../../common/components/FormFields";

interface FamilyTreeRelationProps {
  varPrefix: string;
  nameLabel: string;
  index: number;
}

const familyMembers = [
  { relation: "mother", title: "Mother" },
  { relation: "father", title: "Father" },
  { relation: "maternalGrandmother", title: "Maternal Grandmother" },
  { relation: "maternalGrandfather", title: "Maternal Grandfather" },
  { relation: "paternalGrandmother", title: "Paternal Grandmother" },
  { relation: "paternalGrandfather", title: "Paternal Grandfather" },
];

const FamilyTreeRelation: React.FC<FamilyTreeRelationProps> = ({
  varPrefix,
  nameLabel,
  index,
}) => {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Field
        name={`familyTree[${index}].relation`}
        initialValue={varPrefix}
        type="hidden"
        render={({ input, meta }) => <input {...input} />}
      />
      <Grid item xs={true}>
        <TextFormField
          name={`familyTree[${index}].firstName`}
          label={`${nameLabel} First Name`}
        />
      </Grid>
      <Grid item xs={true}>
        <TextFormField
          name={`familyTree[${index}].lastName`}
          label={`${nameLabel} Last Name`}
          noIcon
        />
      </Grid>
      <Grid item>
        <CheckboxFormField
          name={`familyTree[${index}].inuvialuit`}
          label="Inuvialuit"
          noIcon
        />
      </Grid>
    </Grid>
  );
};

export interface FamilyTreeInfoProps {}

export const FamilyTreeInfo: React.FC<FamilyTreeInfoProps> = ({}) => {
  return (
    <Box>
      <Box mb={0.5}>
        <FormLabel>Family Tree</FormLabel>
      </Box>
      <Stack gap={1} mt={1}>
        {familyMembers.map((f, index) => {
          return (
            <FamilyTreeRelation
              varPrefix={f.relation}
              nameLabel={f.title}
              index={index}
              key={f.relation}
            />
          );
        })}
      </Stack>
    </Box>
  );
};
