import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FieldArray } from "react-final-form-arrays";
import { CfiItem } from "./cfiTypes";
import MuiDelete from "@mui/icons-material/Delete";
import { MuiAdd, MuiPerson } from "../common/icons";
import {
  SelectFormField,
  TextFormField,
} from "../common/components/FormFields";
import MuiInventory from "@mui/icons-material/Inventory";
import MuiDollar from "@mui/icons-material/AttachMoney";

interface ChildFieldsProps {
  namespace: string;
}

function ChildFields({ namespace }: ChildFieldsProps) {
  const theme = useTheme();

  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container spacing={0.5} flex={1}>
      <Grid item md={4} xs={12}>
        <TextFormField
          name={`${namespace}.name`}
          label="Name"
          placeholder="Product, Service or Support Needed"
          required
          icon={<MuiInventory />}
        />
      </Grid>
      <Grid item md={8} xs={12}>
        <TextFormField
          name={`${namespace}.description`}
          label="Description"
          placeholder="Describe how this requested service or support will meet your child's needs"
          required
          noIcon={md}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <SelectFormField
          name={`${namespace}.lengthOfTime`}
          label="Duration"
          options={["One Time Purchase", "3 Months", "6 Months"]}
          required
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFormField
          name={`${namespace}.estimatedCost`}
          label="Estimated Cost"
          required
          noIcon={md}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFormField
          name={`${namespace}.purchaseLocation`}
          label="Purchase Location"
          placeholder="Where the item should be purchased"
          required
          noIcon={md}
        />
      </Grid>
    </Grid>
  );
}

export const DEFAULT_ITEM_VALUES: CfiItem = {
  name: "",
  description: "",
  lengthOfTime: "",
  estimatedCost: "",
  purchaseLocation: "",
};

export interface ItemFieldsListProps {}

export function ItemFieldsList({}: ItemFieldsListProps) {
  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Items</Typography>
      </Box>
      <FieldArray<CfiItem>
        name="items"
        render={({ fields }) => (
          <Stack gap={1}>
            {fields.map((name, index) => (
              <Stack direction="row" alignItems="center" gap={1}>
                <ChildFields namespace={name} />
                <IconButton color="error" onClick={() => fields.remove(index)}>
                  <MuiDelete />
                </IconButton>
              </Stack>
            ))}
            <Box ml={5}>
              <Button
                startIcon={<MuiAdd />}
                variant="outlined"
                onClick={() => fields.push({ ...DEFAULT_ITEM_VALUES })}
              >
                Add Item
              </Button>
            </Box>
          </Stack>
        )}
      />
    </Box>
  );
}
