import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { FieldArray } from "react-final-form-arrays";
import { ChildFields } from "./ChildFields";
import MuiDelete from "@mui/icons-material/Delete";

import { MuiAdd } from "../../common/icons";
import { Child } from "../../common/api/maligaksatFamilyInfoApi";

export const DEFAULT_CHILD_FIELDS: Child = {
  name: "",
  dateOfBirth: "",
  gender: "",
  affiliated: "",
  otherIndigenousOrgInfo: "",
  NIHBNumber: "",
  livingWith: "",
  phoneNumber: "",
  homeAddress: "",
};

export interface ChildrenFieldsProps {}

export function ChildrenFields({}: ChildrenFieldsProps) {
  return (
    <Box>
      <FieldArray<Child>
        name="children"
        initialValue={[{ ...DEFAULT_CHILD_FIELDS }]}
        render={({ fields }) => (
          <Stack gap={1}>
            {fields.map((name, index) => (
              <Stack direction="row" alignItems="center" gap={1}>
                <ChildFields index={index} />
                {index != 0 && (
                  <IconButton
                    color="error"
                    onClick={() => fields.remove(index)}
                  >
                    <MuiDelete />
                  </IconButton>
                )}
              </Stack>
            ))}
            <Box ml={5}>
              <Button
                startIcon={<MuiAdd />}
                variant="outlined"
                onClick={() => fields.push({ ...DEFAULT_CHILD_FIELDS })}
              >
                Add Child
              </Button>
            </Box>
          </Stack>
        )}
      />
    </Box>
  );
}
