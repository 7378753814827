import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Field } from "react-final-form";
import { FormFieldWrapperProps, FormFieldWrapper } from "./FormFieldWrapper";
import dayjs, { Dayjs } from "dayjs";

export interface DateFormFieldProps
  extends Omit<FormFieldWrapperProps, "children"> {
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  stringOutput?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

export const DateFormField: React.FC<DateFormFieldProps> = ({
  name,
  label,
  required,
  disabled,
  stringOutput,
  minDate,
  maxDate,
  ...wrapperProps
}) => {
  return (
    <FormFieldWrapper {...wrapperProps}>
      <Field
        name={name}
        validate={(v) =>
          required && v == null ? "This field is required" : undefined
        }
        render={({ input: { onChange, value, ...input }, meta }) => (
          <DatePicker
            disabled={disabled}
            label={label}
            slotProps={{
              textField: {
                fullWidth: true,
                required,
                disabled,
                inputProps: input,
                color: meta.touched && meta.error ? "error" : undefined,
                helperText: meta.touched && meta.error,
              },
            }}
            format="DD/MM/YYYY"
            minDate={minDate}
            maxDate={maxDate}
            value={value == "" ? undefined : dayjs(value)}
            onChange={(v: Dayjs | null) =>
              stringOutput ? onChange(v?.toJSON()) : onChange(v)
            }
          />
        )}
      />
    </FormFieldWrapper>
  );
};
