import { Box, Divider, Grid, useTheme } from "@mui/material";
import React from "react";
import { TextFormField } from "../../common/components/FormFields";
import { MuiPerson, MuiPhone } from "../../common/icons";

export interface FileInfoFieldsProps {}

export const FileInfoFields: React.FC<
  FileInfoFieldsProps
> = ({}: FileInfoFieldsProps) => {
  const theme = useTheme();

  return (
    <Box>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={1}>
        <Grid item sm={12} xs={12}>
          <TextFormField
            name="fileProtections"
            label="Current Protection Concerns"
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextFormField
            name="supportsOffered"
            label="Supports Offered"
            icon={<MuiPhone />}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextFormField name="agreement" label="Agreement/Order" required />
        </Grid>
      </Grid>
    </Box>
  );
};
