import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FieldArray } from "react-final-form-arrays";
import { MuiAdd, MuiPerson } from "../common/icons";
import { CdcIndividual } from "./CdcTypes";
import MuiDelete from "@mui/icons-material/Delete";
import { FileFormField, TextFormField } from "../common/components/FormFields";

export interface CdcIndividualsProps {
  namespace: string;
  label: string;
  disclaimer?: string;
  includeFiles?: boolean;
}

export const DEFAULT_INDIVIDUAL_FIELDS: Partial<CdcIndividual> = {
  name: "",
  documents: [],
};

export function CdcIndividualsFields({
  namespace,
  label,
  disclaimer,
  includeFiles,
}: CdcIndividualsProps) {
  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">{label}</Typography>
      </Box>
      <FieldArray<Partial<CdcIndividual>>
        name={namespace}
        render={({ fields }) => (
          <Stack gap={1}>
            {disclaimer && (
              <Grid item xs={12}>
                <Box ml={5}>
                  <Typography variant="caption">{disclaimer}</Typography>
                </Box>
              </Grid>
            )}
            {fields.map((name, index) => (
              <Stack key={index} direction="row" alignItems="center" gap={1}>
                <Grid container spacing={0.5} flex={1}>
                  <Grid item sm={6} xs={12}>
                    <TextFormField
                      name={`${name}.name`}
                      label="Name"
                      required
                      icon={<MuiPerson />}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextFormField
                      name={`${name}.relation`}
                      label="Relation"
                      required
                      noIcon
                    />
                  </Grid>
                  {includeFiles && (
                    <Grid item sm={6} xs={12}>
                      <FileFormField name={`${name}.documents`} multiFile />
                    </Grid>
                  )}
                </Grid>
                <IconButton color="error" onClick={() => fields.remove(index)}>
                  <MuiDelete />
                </IconButton>
              </Stack>
            ))}
            <Box ml={5}>
              <Button
                startIcon={<MuiAdd />}
                variant="outlined"
                onClick={() => fields.push({ ...DEFAULT_INDIVIDUAL_FIELDS })}
              >
                Add Individual
              </Button>
            </Box>
          </Stack>
        )}
      />
    </Box>
  );
}
