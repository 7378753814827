import { useEffect, useState } from "react"

export function useRecaptchaReady() {
    const [ready, setReady] = useState(false);
    useEffect(() => {
        grecaptcha.enterprise.ready(function() {
            setReady(true);
        })
    })
    return ready;
}