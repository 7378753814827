import { Box, FormControl, FormHelperText, FormLabel } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";
import { FileDropper } from "../FileDropper";
import { FormFieldWrapper, FormFieldWrapperProps } from "./FormFieldWrapper";

export interface FileFormFieldProps
  extends Omit<FormFieldWrapperProps, "children"> {
  name: string;
  label?: string;
  required?: boolean;
  multiFile?: boolean;
}

export const FileFormField: React.FC<FileFormFieldProps> = ({
  name,
  label,
  required,
  multiFile,
  ...wrapperProps
}) => {
  return (
    <FormFieldWrapper {...wrapperProps}>
      <FormControl fullWidth>
        <FormLabel required={required}>{label}</FormLabel>
        <Field
          name={name}
          type="file"
          validate={(v) =>
            required && v == null ? "This field is required" : undefined
          }
          render={({ input, meta }) => (
            <>
              <FileDropper onChange={input.onChange} multiFile={multiFile} />
              {meta.touched && meta.error && (
                <Box p={1}>
                  <FormHelperText error>{meta.error}</FormHelperText>
                </Box>
              )}
            </>
          )}
        />
      </FormControl>
    </FormFieldWrapper>
  );
};
