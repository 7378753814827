import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { CheckboxFormField } from "../../common/components/FormFields";

export interface RegistrationConsentProps {}

export const RegistrationConsent: React.FC<RegistrationConsentProps> = ({}) => {
  return (
    <Stack gap={2}>
      <Box>
        <Typography variant="h6" paragraph>
          REGISTRANT DECLARATION AND CONSENT
        </Typography>
        <CheckboxFormField
          name="consentAccept"
          mustBeTrue
          label="As a parent, guardian, or legal representative of the child, I declare
          the information provided is true. *"
        />
      </Box>
      <Box>
        <Typography variant="h6" paragraph>
          CONSENT FOR THE RELEASE OF PERSONAL INFORMATION
        </Typography>
        <CheckboxFormField
          name="releaseAccept"
          mustBeTrue
          label="I consent to the collection, use and disclosure of the child's
          personal information as specified in the document “Consent for
          Collection, Use and Disclosure of Personal Information” available on
          the IRC registration website. *"
        />
      </Box>
    </Stack>
  );
};
